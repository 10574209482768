/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 2/3/2021
 * @Example
 */

import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {connect}                                                  from "react-redux";
import {bindActionCreators, compose}                              from "redux";
import PropTypes                                                  from "prop-types";
import {FormGroup}                                                from "react-bootstrap";
import {Grid}                                                     from "@material-ui/core";
import Modal                                                      from "../../../../common/modal/modal";
import ButtonGeneral                                              from "../../../../common/button/button-general";
import * as generalActions                                        from "../../../../../redux/general/general_actions";
import Config                                                     from "../../../../../config";
import * as _                                                     from "lodash";
import {Column}                                                   from "devextreme-react/data-grid";
import GridContainer                                              from "../../../../grid-container/grid-container";
import UserImage                                                  from "../../../../common/user/user-image";
import SearchIcon                                                 from '@material-ui/icons/Search';
import IconButton                                                 from "@material-ui/core/IconButton";
import TextField                                                  from "../../../../common/form-material/textfield";
import * as W09F3010Actions
                                                                  from "../../../../../redux/W0X/W09F3010/W09F3010_actions";

// const useStyles = makeStyles(theme => ({}));

const SelectEmployees = React.memo((props) => {

    const _initDataSource                                          = {
        total: 0,
        rows:  []
    };
    const _initFilter                                              = {
        search: "",
        limit:  20,
        skip:   0
    };
    const {open, title, data, allowSaveData, keyExpr, valueExpr, displayExpr, FormID, loadParams, autoSearch} = props;
    const [loading, setLoading]                                    = useState(false);

    const keys                        = useMemo(() => {
        return data ? data.map(d => d[keyExpr]) : [];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, open]);
    const [dataSource, setDataSource] = useState(_initDataSource);
    const filter                      = useRef(_initFilter);
    const tmpSelectedRowKeys          = useRef([]);
    const tmpSelectedRowData          = useRef([]);
    const dataGrid                    = useRef(null);
    const dataGridSelected            = useRef(null);
    const changePage                  = useRef(false);
    const changeDataSource            = useRef(false);
    const searchTimeout               = useRef(null);

    const loadCboEmployees = (isReset) => {
        const params = {
            HostID:   "",
            Type:     "EmployeeID",
            FormID:   FormID,
            ...loadParams,
            Language: Config.language || "84",
            skip:     filter.current.skip,
            limit:    filter.current.limit,
            search:   filter.current.search,
        };
        if (!isReset) {
            changePage.current = true;
        }
        setLoading(true);
        props.generalActions.getCboEmployees(params, (error, data) => {
            setLoading(false);
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data) {
                setDataSource(data);
            }
        });
    };

    useEffect(() => {
        if (open) {
            tmpSelectedRowKeys.current = keys;
            tmpSelectedRowData.current = data ? [...data] : [];
            loadCboEmployees(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    useEffect(() => {
        changeDataSource.current = true;
        // _setSelected(true);
    }, [dataSource]);

    const onSave = () => {
        const {saveParams, onChosen} = props;
        if (allowSaveData) {
            const params = {
                ...saveParams,
                data:    JSON.stringify(tmpSelectedRowKeys.current),
            };
            setLoading(true);
            props.W09F3010Actions.selectedEmployees(params, (errors) => {
                setLoading(false);
                if (errors) {
                    Config.popup.show('ERROR', errors);
                    return false;
                }
                if (onChosen) onChosen({
                    selectedRowKeys:  tmpSelectedRowKeys.current,
                    selectedRowsData: tmpSelectedRowData.current
                });
                onClose();
            });
        } else {
            if (onChosen) onChosen({
                selectedRowKeys:  tmpSelectedRowKeys.current,
                selectedRowsData: tmpSelectedRowData.current
            });
            onClose();
        }
    };

    const onChangePage = (page) => {
        filter.current.skip = page * filter.current.limit;
        loadCboEmployees();
    };

    const onChangePerPage = (per) => {
        filter.current.skip  = 0;
        filter.current.limit = per;
        loadCboEmployees();
    };

    const onFilter = () => {
        filter.current.skip  = 0;
        loadCboEmployees();
    };

    const onClose = () => {
        const {onClose} = props;
        if (onClose) onClose();
    };

    const _setSelected     = (flag) => {
        if (dataGrid && dataGrid.current) {
            let selected = tmpSelectedRowKeys.current;
            if (flag) {
                selected = [];
                dataSource.rows.forEach(d => {
                    if (tmpSelectedRowKeys.current.indexOf(d[keyExpr]) > -1) {
                        selected.push(d[keyExpr]);
                    }
                });
                if (changePage.current) {
                    setTimeout(() => changePage.current = false, 200);
                }
            }
            dataGrid.current.instance.selectRows(selected);
        }
    };
    const _setDataSelected = () => {
        if (dataGridSelected && dataGridSelected.current) {
            dataGridSelected.current.instance.option("dataSource", tmpSelectedRowData.current);
        }
    };

    const onContentReady = () => {
        if (changeDataSource.current) {
            _setSelected(true);
            _setDataSelected();
            changeDataSource.current = false;
        }
    };

    const onSelectionChanged = useCallback((e) => {
        const {currentSelectedRowKeys, selectedRowsData, currentDeselectedRowKeys} = e;
        if (currentDeselectedRowKeys.length > 0) {
            if (!changePage.current) {
                tmpSelectedRowData.current = tmpSelectedRowData.current.filter((item) => {
                    return currentDeselectedRowKeys.indexOf(item[keyExpr]) < 0;
                });
                tmpSelectedRowKeys.current = tmpSelectedRowKeys.current.filter((item) => {
                    return currentDeselectedRowKeys.indexOf(item) < 0;
                });
            }
        } else if (currentSelectedRowKeys.length > 0) {
            for (const val of currentSelectedRowKeys) {
                if (tmpSelectedRowKeys.current.indexOf(val) < 0) {
                    tmpSelectedRowKeys.current.push(val);
                    const data = selectedRowsData.find(d => d[keyExpr] === val);
                    if (data) tmpSelectedRowData.current.push(data);
                }
            }
        }
        if (!changePage.current) {
            _setSelected();
            _setDataSelected();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const renderEmployee = (e) => {
        const {data} = e.rows || e;
        if (displayExpr && typeof displayExpr === "function") return displayExpr();
        return <div className={"display_row align-center valign-top"}>
            <UserImage
                data={data}
                keyExpr={valueExpr ? valueExpr : keyExpr}
                valueExpr={valueExpr ? valueExpr : keyExpr}
                allowHover={false}
                width={24} height={24}
            />
            <div>{`${data[valueExpr]} - ${data[displayExpr]}`}</div>
        </div>;
    };

    const handleChangeSearchValue = (value = '') => {
        if (autoSearch) {
            if (searchTimeout.current) {
                clearTimeout(searchTimeout.current);
            }
            searchTimeout.current = setTimeout(() => {
                filter.current.search = value
                onFilter();
            }, 700);
        }
    };

    const disabled = Boolean(Object.keys(loading).find(l => loading[l]));
    return (
        <>
            <Modal open={open || false}
                   maxWidth={_.get(props, "maxWidth", "md")}
                   fullWidth={_.get(props, "fullWidth", true)}
                   onClose={onClose}
                   title={title}
            >
                <Modal.Content>
                    <Grid container component={"div"} spacing={2}>
                        <Grid item component={"div"} xs={12} md={6}>
                            <FormGroup>
                                <TextField
                                    style={{marginTop: 0}}
                                    placeholder={Config.lang("DHR_Tim_theo_ma_va_ten")}
                                    variant={"outlined"}
                                    defaultValue={filter.current.search}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                                          <IconButton onClick={() => onFilter()}>
                                                              <SearchIcon/>
                                                          </IconButton>
                                                      ),
                                        onKeyDown:    (e) => {
                                            if (!autoSearch && e && e.keyCode && e.keyCode === 13) onFilter();
                                        }
                                    }}
                                    onChange={(e) => handleChangeSearchValue(e.target.value)}
                                    fullWidth
                                />
                            </FormGroup>
                            <GridContainer
                                reference={ref => dataGrid.current = ref}
                                totalItems={_.get(dataSource, "total", [])}
                                itemPerPage={_.get(filter, "current.limit", 50)}
                                skipPerPage={_.get(filter, "current.skip", 0)}
                                dataSource={_.get(dataSource, "rows", [])}
                                loading={loading}
                                keyExpr={keyExpr ? keyExpr : valueExpr}
                                typePaging={"remote"}
                                showColumnHeaders={false}
                                pagerFullScreen={false}
                                showBorders={false}
                                columnAutoWidth={true}
                                typeShort={window.innerWidth < 768}
                                height={"calc(100vh - 300px)"}
                                selection={{
                                    allowSelectAll:     false,
                                    mode:               'multiple',
                                    selectAllMode:      "allPages",
                                    showCheckBoxesMode: "always"
                                }}
                                allowColumnResizing={true}
                                listPerPage={[50, 70, 90, 100]}
                                // selectedRowKey={selectedRowKeys}
                                onChangePage={onChangePage}
                                onChangePerPage={onChangePerPage}
                                onSelectionChanged={onSelectionChanged}
                                onContentReady={() => {
                                    onContentReady();
                                }}
                            >
                                <Column
                                    alignment={"left"}
                                    dataField={""}
                                    width={200}
                                    cellRender={renderEmployee}
                                />
                            </GridContainer>
                        </Grid>
                        <Grid item component={"div"} xs={12} md={6}>
                            <label>{Config.lang("DHR_Thanh_vien_duoc_chon")}</label>
                            <GridContainer
                                reference={ref => dataGridSelected.current = ref}
                                itemPerPage={50}
                                // dataSource={[]}
                                // loading={loading}
                                keyExpr={keyExpr}
                                typePaging={"normal"}
                                showColumnHeaders={false}
                                pagerFullScreen={false}
                                showBorders={false}
                                columnAutoWidth={true}
                                typeShort={window.innerWidth < 768}
                                height={"calc(100vh - 220px)"}
                                allowColumnResizing={true}
                                listPerPage={[50, 70, 90, 100]}
                                // onSelectionChanged={onSelectionChanged}
                            >
                                <Column
                                    alignment={"left"}
                                    dataField={""}
                                    width={200}
                                    cellRender={renderEmployee}
                                />
                            </GridContainer>
                        </Grid>
                    </Grid>
                </Modal.Content>
                <Modal.Actions style={{justifyContent: "space-between"}}>
                    <div/>
                    <div>
                        <ButtonGeneral
                            name={Config.lang("DHR_Luu")}
                            typeButton={"save"}
                            disabled={disabled}
                            style={{textTransform: "uppercase"}}
                            size={"large"}
                            onClick={onSave}
                        />
                    </div>
                </Modal.Actions>
            </Modal>
        </>
    );
}, (pProps, nProps) => {
    return pProps.open === nProps.open &&
        JSON.stringify(pProps.data) === JSON.stringify(nProps.data);
});

SelectEmployees.defaultProps = {
    allowSaveData: true,
    keyExpr:       "EmployeeID",
    autoSearch: false,
};
SelectEmployees.propTypes    = {
    open:          PropTypes.bool,
    title:         PropTypes.string.isRequired,
    maxWidth:      PropTypes.string,
    fullWidth:     PropTypes.bool,
    keyExpr:       PropTypes.string,
    valueExpr:   PropTypes.string,
    displayExpr:   PropTypes.any,
    allowSaveData: PropTypes.bool,
    FormID:        PropTypes.string,
    loadParams:    PropTypes.object,
    saveParams:    PropTypes.object,
    autoSearch:    PropTypes.bool,

    onChosen: PropTypes.func,
    onClose:  PropTypes.func
};

export default compose(connect(null, dispatch => ({
    generalActions:  bindActionCreators(generalActions, dispatch),
    W09F3010Actions: bindActionCreators(W09F3010Actions, dispatch)
})))(SelectEmployees);
