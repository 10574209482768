/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 2/2/2021
 * @Example
 */

import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {bindActionCreators, compose} from "redux";
import {IconButton, makeStyles, Typography} from "@material-ui/core";
import {connect} from "react-redux";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import ButtonGeneral from "../../../common/button/button-general";
import UserImage from "../../../common/user/user-image";
import Icons from "../../../common/icons/";
import Config from "../../../../config";
import * as W09F3010Actions
    from "../../../../redux/W0X/W09F3010/W09F3010_actions";
import {Image, FormGroup, Row, Col} from "react-bootstrap";
import {browserHistory} from "react-router";
import _ from "lodash";
import {LoadPanel} from "devextreme-react";
import Popover
    from "../../../../components/grid-container/popover-action";
import History from "../../../libs/history";
import * as generalActions from "../../../../redux/general/general_actions";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Divider from "@material-ui/core/Divider";
import TabContent, {TabPanel} from "../../../common/tabs/tab-content";
import W09F3010Member from "./utils/W09F3010Member";
import SelectEmployees from "./utils/SelectEmployees";
import CDN from "../../../CDN";
import DOMPurify from "dompurify";

const useStyles = makeStyles(() => ({
    root: {
        background: '#FFFFFF',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        borderRadius: 4
    },
    divImage: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        height: 200,
        '& img': {
            width: '100%',
            height: '100%',
            objectFit: 'contain'
        },
        '&:hover .icon-edit': {
            display: 'block'
        }
    },
    divContent: {
        padding: '0px 15px 15px 15px'
    },
    divMember: {
        minHeight: 40
    },
    divItemMember: {
        background: '#FFFFFF',
        boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25)',
        borderRadius: 4,
        padding: 15,
        marginBottom: 10,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        minHeight: 180,
        position: 'relative',
        '& .divItemMember_button': {
            position: 'absolute',
            top: 5,
            right: 5
        }
    },
    iconEdit: {
        position: 'absolute',
        top: 10,
        right: 10,
        display: 'none',
        transition: 'all 0.4s',
        '& button': {
            backgroundColor: '#FFFFFF'
        }
    }
}));

const W09F3010 = (props) => {
    const getInfo = useCallback((flag) => {
        const {location} = props;
        const {mode, GroupID} = Config.getUrlParams(props);
        if (location && location.state) {
            return {
                mode: mode || 'add',
                GroupID: GroupID || ""
            }
        } else {
            if (flag) {
                browserHistory.push(Config.getRootPath() + "W09F3000");
                return null;
            }
            return {};
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.location]);

    const classes = useStyles();

    const {GroupID} = getInfo() || {};
    const {getForm, getMembers} = props;
    const [tabKey, setTabKey] = useState(0);
    const [loading, setLoading] = useState(false);
    const [showSelectEmployees, setShowSelectEmployees] = useState(false);

    const refAvatar = useRef(null);
    const refAction = useRef(null);

    const loadForm = () => {
        const params = {
            Language: Config.language || "84",
            GroupID: GroupID || ""
        };
        setLoading(true);
        props.W09F3010Actions.loadForm(params, error => {
            setLoading(false);
            if (error) {
                    Config.popup2.show("ERROR", error);
                return false;
            }
        });
    };
    const loadMembers = () => {
        const params = {
            GroupID: GroupID || ""
        };
        setLoading(true);
        props.W09F3010Actions.loadMembers(params, error => {
            setLoading(false);
            if (error) {
                    Config.popup2.show("ERROR", error);
                return false;
            }
        });
    };

    useEffect(() => {
        if (!getInfo(true)) return;
        loadForm();
        loadMembers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onClosePopover = () => {
        if (refAction && refAction.current) refAction.current.instance.hide();
    };

    const onOpenModal = () => {
        setShowSelectEmployees(true);
    };
    const onCloseModal = () => {
        setShowSelectEmployees(false);
    };

    const onBack = () => {
        browserHistory.goBack();
    };

    const onChangeTab = (e, value) => {
        setTabKey(value);
    };

    const checkFileType = filename => {
        const extension = filename.substr(filename.lastIndexOf(".")).toLowerCase();
        const allowedExtensions = [".jpg", ".png"];
        if (extension.length > 0) {
            if (allowedExtensions.indexOf(extension) === -1) {
                return false;
            }
        }
        return true;
    };

    const onUploadFile = (e) => {
        if (!e) return false;
        const arrFile = e.target.files;
        const sizeLimit = 5000000;
        for (let i = 0; i < arrFile.length; i++) {
            let message = '';
            if (arrFile[i].size / 1024 > Number(sizeLimit)) {
                message = Config.lang("Dung_luong_File_khong_duoc_lon_hon") + " " + sizeLimit + ' KB <br>';
                Config.popup2.show("INFO", message);
            } else if (checkFileType(arrFile[i].name) === false) {
                message = Config.lang("Dinh_dang_file_khong_hop_le");
                Config.popup2.show("INFO", message);
            } else {
                setLoading(true);
                CDN.uploadFile([arrFile[i]], null, (error, data) => {
                    if (error) {
                        setLoading(false);
                            Config.popup2.show("ERROR", error);
                        return false;
                    }
                    if (data && data.paths && data.paths.length > 0) {
                        const item = data.paths[0];
                        const params = {
                            GroupID,
                            GroupPicture: _.get(item, 'url',  _.get(item, 'filePath', ''))
                        };
                        props.W09F3010Actions.updatePicGroup(params, async (errors) => {
                            setLoading(false);
                            if (errors) {
                                    Config.popup2.show("ERROR", errors);
                                return false;
                            } else {
                                loadForm();
                                Config.notify.show("success", Config.lang("Luu_thanh_cong"), 2000);
                            }
                        })
                    } else {
                        Config.popup2.show("INFO", Config.lang("Co_loi_xay_ra_trong_qua_trinh_xu_ly"));
                    }
                });
            }
        }
    };

    const onShowAction = (e) => {
        if (refAction && refAction.current) refAction.current.instance.show(e.target);
    };

    const onAction = useCallback(async (mode, data) => {
        if (!mode) return false;
        const {MemberID} = data || {};
        onClosePopover();
        switch (mode) {
            case "changeAvatar":
                if (refAvatar && refAvatar.current) refAvatar.current.click();
                break;
            case "addMember":
                onOpenModal();
                break;
            case "leaveGroup":
                Config.popup2.show("YES_NO", Config.lang("Ban_co_chac_muon_roi_nhom_khong"), () => {
                    const params = {
                        GroupID,
                        MemberID: Config.profile.UserID || ""
                    };
                    setLoading(true);
                    props.W09F3010Actions.leaveGroup(params, async (errors) => {
                        setLoading(false);
                        if (errors) {
                                Config.popup2.show("ERROR", errors);
                            return false;
                        } else {
                            Config.notify.show("success", Config.lang("Xoa_thanh_cong"), 2000);
                            await saveHistory(data);
                            loadMembers();
                        }
                    })
                });
                break;
            case "isAdmin":
                const params = {
                    GroupID,
                    MemberID: MemberID,
                    Role: _.get(data, "newRole", "")
                };
                setLoading(true);
                props.W09F3010Actions.updateRole(params, async (errors) => {
                    setLoading(false);
                    if (errors) {
                            Config.popup2.show("ERROR", errors);
                        return false;
                    } else {
                        await saveHistory(data, true);
                        loadMembers();
                        Config.notify.show("success", Config.lang("Luu_thanh_cong"), 2000);
                    }
                })
                break;
            case "removeFromGroup":
                Config.popup2.show("YES_NO", Config.lang("Ban_co_chac_muon_xoa_thanh_vien_nay_khoi_nhom_khong"), () => {
                    const params = {
                        GroupID,
                        MemberID: MemberID
                    };
                    setLoading(true);
                    props.W09F3010Actions.leaveGroup(params, async (errors) => {
                        setLoading(false);
                        if (errors) {
                                Config.popup2.show("ERROR", errors);
                            return false;
                        } else {
                            Config.notify.show("success", Config.lang("Xoa_thanh_cong"), 2000);
                            await saveHistory(data);
                            loadMembers();
                        }
                    })
                });
                break;
            default:
                break;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onChosen = () => {
        loadMembers();
    };

    const saveHistory = async (data, isUpdateRole = false) => {
        const {Role, newRole, MemberID} = data;
        const {GroupID, GroupName} = props.getForm ?? {GroupID: _.get(data, "GroupID", ""), GroupName: ""};
        const {UserID, UserName} = Config.getUser({EmployeeID: MemberID}) ?? {UserID: "", UserName: ""};
        let _data = {
            GroupName,
            User: `${Config.profile.UseID} - ${Config.profile.UserName}`
        };

        let captions = {
            GroupName: "Ten_nhom",
            User: "data_User"
        };

        const captionOldRoles = ["thanh_vien", "quan_tri", "thanh_vien_vai_tro_quan_tri"];
        const captionNewRoles = ["thanh_vien", "quan_tri_vien", "thanh_vien_vai_tro_quan_tri"];

        let options = {
            data: _data,
            captions: captions,
            ModuleID: "D09",
            TransID: GroupID,
            action: 3,
            TransactionID: "W09F3000",
            TransactionName: "Nhóm",
        };

        if (GroupID) {
            if (isUpdateRole) {
                options = {
                    ...options,
                    data: {
                        ..._data,
                        NewRole: {
                            "add": [{
                                "ma_nhan_vien": UserID ?? "",
                                "ten_nhan_vien": UserName ?? ""
                            }]
                        },
                        OldRole: {
                            "removed": [{
                                "ma_nhan_vien": UserID ?? "",
                                "ten_nhan_vien": UserName ?? ""
                            }]
                        }
                    },
                    dataCompare: {
                        ..._data,
                        NewRole: {
                            "add": [{
                                "ma_nhan_vien": UserID ?? "",
                                "ten_nhan_vien": UserName ?? ""
                            }]
                        },
                        OldRole: {
                            "removed": [{
                                "ma_nhan_vien": UserID ?? "",
                                "ten_nhan_vien": UserName ?? ""
                            }]
                        }
                    },
                    captions: {
                        ...captions,
                        NewRole: captionNewRoles[newRole],
                        OldRole: captionOldRoles[Role],
                    },
                    action: 1,
                    TransactionID: "W09F3000",
                    TransactionName: "Chi tiết nhóm",
                };
            }
            const history = new History(options);
            // const allData = await history.get();
            // history.createDetailHistory("Nhan_vien", [], [], captions,"key",  null, options ); //Create detail..
            // console.log('test', allData);
            await history.save();
        } else {
            Config.notify.show("error", Config.lang("Luu_lich_su_khong_thanh_cong"), 2000);
        }
    };

    const renderMembersWithRole = useCallback((role1 = 0, role2) => {
        const Members = getMembers || [];
        let listMembers = !_.isUndefined(role1) ? Members.filter(m => m.Role === role1) : Members;
        listMembers = !_.isUndefined(role2) ? listMembers.concat(Members.filter(m => m.Role === role2)) : listMembers;
        return (
            <div className={"display_row align-center pdt5 pdb5"}>
                {listMembers && listMembers.map((member, idx) => {
                    return <UserImage
                        key={idx}
                        style={{marginRight: 25}}
                        data={member}
                        keyExpr={"MemberID"}
                        valueExpr={"UserID"}
                        width={40} height={40}
                    />
                })}
            </div>
        );
    }, [getMembers]);

    const GroupPicture = useMemo(() => {
        let groupPicture = _.get(getForm, "GroupPicture", "");
        return groupPicture
            ? (groupPicture.includes('http') ? groupPicture : Config.getCDNPath() + groupPicture)
            : require("../../../../assets/images/general/no-image.svg");
    }, [getForm]);
    return (
        <>
            <SelectEmployees
                open={showSelectEmployees}
                keyExpr={"UserID"}
                valueExpr={"UserID"}
                displayExpr={"EmployeeName"}
                // allowSaveData={false}
                FormID={"W09F3000"}
                loadParams={{
                    Mode: 2,
                    search2: GroupID
                }}
                saveParams={{
                    GroupID: GroupID
                }}
                // data={dataOldMembers}
                title={Config.lang("Them_thanh_vien")}
                onClose={onCloseModal}
                onChosen={onChosen}
                autoSearch
            />
            <LoadPanel
                shadingColor="rgba(0,0,0,0.4)"
                position={{
                    my: 'center',
                    of: "#root"
                }}
                visible={loading}
                showIndicator={true}
                shading={true}
                showPane={true}
            />
            <Popover
                reference={refAction}
                width="auto"
                position="bottom"
                shading={false}
            >
                <div className={"display_col align-left"} style={{padding: "5px 10px"}}>
                    {_.get(getForm, "IsAdmin", 0) === 1 && <ButtonGeneral
                        name={Config.lang('Them_thanh_vien')}
                        variant={"custom"}
                        size={"small"}
                        style={{
                            width: '100%',
                            justifyContent: 'flex-start'
                        }}
                        onClick={() => onAction('addMember')}
                    />}
                    <ButtonGeneral
                        name={Config.lang('Roi_khoi_nhom')}
                        variant={"custom"}
                        size={"small"}
                        style={{
                            width: '100%',
                            justifyContent: 'flex-start'
                        }}
                        className={'valign-top'}
                        onClick={() => onAction("leaveGroup", getForm)}
                    />
                </div>
            </Popover>
            <ActionToolbar
                title={_.get(getForm, "GroupName", "")}
                onBack={onBack}
            />
            <FormGroup>
                <Row>
                    <Col xs={12} sm={12} md={10} lg={8}>
                        <div className={classes.root}>
                            <div className={classes.divImage}>
                                <Image src={GroupPicture}/>
                                <input type="file" accept="image/*" onChange={onUploadFile} ref={refAvatar}
                                       style={{display: "none"}}/>
                                {_.get(getForm, "IsAdmin", 0) === 1 && <div className={classes.iconEdit + " icon-edit"}>
                                    <IconButton href={""} size={"small"} disabled={false}
                                                onClick={() => onAction('changeAvatar')}>
                                        <Icons name={"edit"}/>
                                    </IconButton>
                                </div>}
                            </div>
                            <div className={classes.divContent}>
                                <FormGroup style={{marginBottom: 24}}>
                                    <div className={"display_row align-center align-between"}>
                                        <Tabs
                                            indicatorColor={"primary"}
                                            textColor={"primary"}
                                            value={tabKey}
                                            onChange={onChangeTab}
                                            // className={classes.tabs}
                                        >
                                            <Tab style={{
                                                minWidth: 70,
                                                minHeight: 42
                                            }} value={0} label={Config.lang("Gioi_thieu")}/>
                                            <Tab style={{
                                                minWidth: 70,
                                                minHeight: 42
                                            }} value={1} label={Config.lang("Thanh_vien")}/>
                                        </Tabs>
                                        {_.get(getForm, "IsAdmin", 0) !== 2 &&
                                        <IconButton size={"small"} disabled={false} onClick={onShowAction}>
                                            <Icons name={"biggroup"}/>
                                        </IconButton>}
                                    </div>
                                    <Divider component={"div"}/>
                                </FormGroup>
                                <TabContent
                                    activeKey={tabKey}
                                    disableSwipe={true}
                                >
                                    <TabPanel index={0}>
                                        <FormGroup>
                                            <fieldset>
                                                <label
                                                    className={"mgb10"}>{Config.lang("Gioi_thieu_ve_nhom")}</label>
                                                <Typography
                                                    dangerouslySetInnerHTML={{
                                                        __html: DOMPurify.sanitize(_.get(getForm, "Description", "")),
                                                    }}
                                                    style={{fontWeight: 400, fontSize: "1.1rem"}}
                                                />
                                            </fieldset>
                                        </FormGroup>
                                        <Divider component={"div"} className={"mgb15"}/>
                                        <FormGroup>
                                            <fieldset>
                                                <div>{Config.lang("Thanh_vien")}</div>
                                                <div className={classes.divMember}>
                                                    {renderMembersWithRole(0, 2)}
                                                </div>
                                                <div>{Config.lang("Quan_tri")}</div>
                                                <div className={classes.divMember}>
                                                    {renderMembersWithRole(1, 2)}
                                                </div>
                                            </fieldset>
                                        </FormGroup>
                                    </TabPanel>
                                    <TabPanel index={1}>
                                        <W09F3010Member data={getForm} members={getMembers} onAction={onAction}/>
                                    </TabPanel>
                                </TabContent>
                            </div>
                        </div>
                    </Col>
                </Row>
            </FormGroup>
        </>
    );
};

export default compose(
    connect(
        state => ({
            getForm: state.W09F3010.getForm,
            getMembers: state.W09F3010.getMembers,
        }),
        dispatch => ({
            generalActions: bindActionCreators(generalActions, dispatch),
            W09F3010Actions: bindActionCreators(W09F3010Actions, dispatch)
        })
    ),
)(W09F3010);
