/**
 * @copyright 2020 @ DigiNet
 * @author DINHTHIEN
 * @create 4/9/2020
 * @Example
 */

import React, { Component } from "react";
import { connect } from "react-redux";
import * as W09F9000Actions from "../../../../redux/W0X/W09F9000/W09F9000_actions";
import * as generalActions from "../../../../redux/general/general_actions";
import { bindActionCreators, compose } from "redux";
import Config                          from "../../../../config";
import ButtonGeneral       from "../../../common/button/button-general";
import { withStyles }      from "@material-ui/core";
import ActionToolbar       from "../../../common/toolbar/action-toolbar"
import Api                 from "../../../../services/api";
import _                   from "lodash";
import W09F9000ManualLeft  from "./W09F9000ManualLeft";
import W09F9000ManualRight from "./W09F9000ManualRight";
import Filter              from "../../../filter/filter";
import {Col, Row}          from "react-bootstrap";
const styles = (theme) => {
    return {
        colLeft: {
            [theme.breakpoints.up('sm')]: {
                maxWidth: 280
            }
        },
        header: {
            zIndex: 0,
        },
    };
};
class W09F9000 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            groupID: "",
            iPermission: 0,
            gridLoading: false,
            editRow: false,
            isNewRow: false,
            mode: "",
            searchValue: ""
        };
        this.refDataRow = null;
    }

    isValidURL = string => {
        // eslint-disable-next-line no-useless-escape
        let regex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
        return regex.test(string.trim())
    };


    onSearch = e => {
        if (this.timer) { clearTimeout(this.timer) };
        this.timer = setTimeout(() => {
            this.setState({
                searchValue: e.target.value
            })
        }, 500);
    }

    onChangeTabs = id => {
        this.setState({ groupID: id }, () => { this.loadDataManualRight(id) })
    }

    onEditRow = (e, type) => {
        const { isNewRow } = this.state;
        this.setState({ editRow: true, mode: type }, () => {
            setTimeout(() => {
                if (type === "add" && this.refDataRow) {
                    !isNewRow && this.refDataRow.instance.addRow();
                    this.setState({ isNewRow: true })
                } else if (e) {
                    if (type === "edit") {
                        e.component.editRow(e.rowIndex);
                    } else {
                        Config.popup.show("YES_NO", Config.lang("DHR_Ban_co_chac_muon_xoa?"), () => { e.component.deleteRow(e.rowIndex); this.onRowUpdated(e) });
                    };
                }
            }, 300);
        });
    };

    onRowUpdated = async e => {
        if (!e) return false;
        const { data } = e;
        const { mode, groupID } = this.state;
        const ItemID = _.get(data, "ItemID", "");
        if (!ItemID && (mode === "edit" || mode === "delete")) {
            Config.popup.show("INFO", `ItemID ${Config.lang("DHR_Bat_buoc")}`);
            return false;
        }
        if (!groupID && mode === "add") {
            Config.popup.show("INFO", `GroupID ${Config.lang("DHR_Bat_buoc")}`);
            return false;
        };
        const params = {
            GroupID: groupID,
            ItemID,
            URL: _.get(data, "URL", "").trim(),
            ItemDes: _.get(data, "ItemDes", "").trim(),
            OrderNo: _.get(data, "OrderNo", "")
        }
        this.setState({ gridLoading: true });
        mode && await Api.put(`/w09f9000/${mode}`, params).then(({ code = "" }) => {
            let sMessage = "";
            let type = "";
            switch (code) {
                case 200:
                    type = "success"
                    sMessage = Config.lang("DHR_Luu_thanh_cong")
                    break;
                default:
                    type = "INFO"
                    sMessage = Config.lang("DHR_Loi_chua_xac_dinh")
                    break;
            }
            Config.notify.show(type, sMessage, 2000);
            this.setState({ isNewRow: false, editRow: false, gridLoading: false, searchValue: "" }, () => this.loadDataManualRight(groupID))
        }).catch(err => { this.setState({ gridLoading: false }); });

    }

    loadPermission = async () => {
        await this.props.generalActions.getPermission("W09F9000", isPer => {
            this.setState({ iPermission: isPer });
        });
    };

    loadDataManualLeft = () => {
        this.setState({ gridLoading: true });
        return new Promise((rl, rj) => {
            this.props.w09f9000Actions.loadUserManualLeft((error, data) => {
                if (error) {
                    rj(error);
                } else {
                    const firstId = _.get(data, "[0]GroupID", "");
                    this.setState({ groupID: firstId, gridLoading: false });
                    rl(firstId);
                }
                this.setState({ gridLoading: false });
            })
        })
    }

    loadDataManualRight = id => {
        this.setState({ gridLoading: true });
        this.props.w09f9000Actions.loadUserManualRight({ GroupID: id }, error => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            this.setState({ gridLoading: false });
        });
    }

    componentDidMount = async () => {
        await this.loadPermission();
        // if (this.state.iPermission <= 0) return;
        this.loadDataManualLeft().then(id => { this.loadDataManualRight(id) }).catch(err => {
            let message = err.message || Config.lang("DHR_Loi_chua_xac_dinh");
            Config.popup.show("INFO", message);
        });
    }

    render() {
        const { classes, getManualLeftPanel } = this.props;
        const { groupID, iPermission, editRow, gridLoading, searchValue, isNewRow } = this.state;
        return (
            <>
                <ActionToolbar
                    className={classes.header}
                    title={Config.lang("DHR_Huong_dan_su_dung")}
                >
                    <ButtonGeneral name={Config.lang('DHR_Them')}
                                   typeButton={'add'}
                                   color={"primary"}
                                   disabled={iPermission <= 1 || isNewRow}
                                   variant={"custom"}
                                   loading={false}
                                   style={{ textTransform: 'uppercase' }}
                                   size={"large"}
                                   onClick={() => this.onEditRow(null, "add")} />
                </ActionToolbar>
            <div>
                <div className={"hidden"}>
                    <Filter
                        value={searchValue}
                        onTextChanged={this.onSearch}
                        placeholder={Config.lang("DHR_Noi_dung_can_tim_kiem")} />
                </div>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={3} className={classes.colLeft}>
                        <W09F9000ManualLeft
                            tabIndex={groupID || _.get(getManualLeftPanel, "[0]GroupID", "")}
                            handleChangeTab={id => { this.setState({ isNewRow: false }, () => { this.onChangeTabs(id); }) }} />
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={9}>
                    <div>
                        <div style={{ zIndex: 1501 }}>
                            <W09F9000ManualRight
                                groupID={groupID}
                                isPermission={iPermission}
                                isEditing={editRow}
                                searchValue={searchValue}
                                gridLoading={gridLoading}
                                checkUrl={e => this.isValidURL(e.value)}
                                onNewRow={() => { this.setState({ isNewRow: false }) }}
                                refDataRow={ref => this.refDataRow = ref}
                                onEditRow={this.onEditRow}
                                onCanCelEdit={() => this.onCanCelEditing()}
                                onRowUpdated={this.onRowUpdated}
                            />
                        </div>
                    </div>
                    </Col>
                </Row>
            </div>
                </>
        )
    }
}

export default compose(connect((state) => ({
    getManualLeftPanel: state.W09F9000.getManualLeftPanel,
    getManualRightPanel: state.W09F9000.getManualRightPanel
}), (dispatch) => ({
    generalActions: bindActionCreators(generalActions, dispatch),
    w09f9000Actions: bindActionCreators(W09F9000Actions, dispatch)
})), withStyles(styles, {withTheme: true}))(W09F9000);
