import React, { Component } from "react";
import { connect } from "react-redux";

import GridContainer from "../../../grid-container/grid-container";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import Config from "../../../../config";
import { bindActionCreators, compose } from "redux";
import { FormGroup, Col, Row, Image } from "react-bootstrap";
import Filter from "../../../filter/filter";
import { Combo } from "../../../common/form-material";
import { Column } from "devextreme-react/data-grid";
import _ from "lodash";
import withStyles from "@material-ui/core/styles/withStyles";
import * as w21f2005Actions from "../../../../redux/W2X/W21F2005/W21F2005_actions";
import * as generalActions from "../../../../redux/general/general_actions";
import W21F2005FormDetail from "./W21F2005FormDetail";
import Api from "../../../../services/api";
import {ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails ,Typography} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ButtonGeneral from "../../../common/button/button-general";

const styles = {
    root: {
        marginBottom: 15,
        borderRadius: 0,
        boxShadow: "none",
        backgroundColor: "#F3F3F3",
    },
    labelCheckbox: {
        fontSize: "0.8rem",
    },
    checkbox: {
        padding: "0 4px",
    },
    tab: {
        maxWidth: "fit-content",
        padding: "15px 58px",
    },
    tabActive: {
        backgroundColor: "#fff",
    },
    btnFilter: {
        backgroundColor: "#9999993d",
        textTransform: "uppercase",
    },
    imgAvatar: {
        width: 20,
        minWidth: 20,
        height: 20,
        objectFit: "cover",
        borderRadius: 20,
        marginRight: 8,
    },
    expansionPanelSummary: {
        boder: 0,
        "& .MuiExpansionPanelSummary-content": {
            flexGrow: 0,
            margin: 0,
        },
        "&.MuiExpansionPanelSummary-root": {
            margin: 0,
            justifyContent: "flex-end",
            flexDirection: "row-reverse",
            minHeight: 0,
            padding: 0
        },
        "& .MuiIconButton-root": {
            padding: 0
        }
    },
    expansionPanel: {
        boxShadow: "none",
        margin: 0,
        "&:before": {
            backgroundColor: "transparent"
        }
    },
};

class W21F2005 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: true,
            iPermission: 0,
            cboPeriodTime: [],
            PeriodTimeTo: { PeriodTime: "", TranMonthTo: 0, TranYearTo: 0 },
            PeriodTimeFrom: { PeriodTime: "", TranMonthFrom: 0, TranYearFrom: 0 },
            gridLoading: false,
            cboLoading: false,
            employeeSelected: [],
        };
        this.filter = {
            TypeID: "",
            GrantTypeID: "",
            fieldSort: "",
            typeSort: "",
            skip: 0,
            limit: 10,
        };
    }

    loadPermission = async () => {
        await this.props.generalActions.getPermission("W21F2005", (isPer) => {
            this.setState({ iPermission: isPer });
        });
    };

    loadApi = (action, typeAction = "w21f2005Actions") => {
        let { PeriodTimeTo, PeriodTimeFrom } = this.state;
        const { skip, limit, TypeID, GrantTypeID, fieldSort, typeSort } = this.filter;
        let params = {};
        const loading = action === "getGrid" ? "gridLoading" : "cboLoading";
        if (action === "getGrid") {
            params = {
                DivisionID: Config.getDivisionID(),
                FormID: "W21F2005",
                UserID: Config.profile.UserID,
                GrantTypeID,
                TypeID,
                fieldSort,
                typeSort,
                skip,
                limit,
                ..._.omit(PeriodTimeFrom, ["PeriodTime"]),
                ..._.omit(PeriodTimeTo, ["PeriodTime"]),
            };
        }

        if (action === "getCboGrantType") params = { TypeID, skip };

        this.setState({ [loading]: true });
        this.props[typeAction][action](params, (err) => {
            if (err) {
                let message = err.message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                this.setState({ gridLoading: false });
                return false;
            }
            this.setState({ [loading]: false });
        });
    };

    handleFilterChange = (key, data) => {
        const stateFieldName = ["To", "From"];
        const value = _.get(data, "value", "");
        if (stateFieldName.includes(key)) {
            const state = `PeriodTime${key}`;
            const tranMonth = `TranMonth${key}`;
            const tranYear = `TranYear${key}`;
            this.setState({
                [state]: {
                    PeriodTime: value,
                    [tranMonth]: value && value.split("/")[0],
                    [tranYear]: value && value.split("/")[1],
                },
            });
            return;
        }
        this.filter[key] = value;
        if (key === "TypeID") this.loadApi("getCboGrantType");
    };

    onChangePage = (page) => {
        this.filter.skip = page * this.filter.limit;
        this.loadApi("getGrid");
    };

    onChangePerPage = (per) => {
        this.filter.skip = 0;
        this.filter.limit = per;
        this.loadApi("getGrid");
    };

    onLoadDataFilter = () => {
        this.loadApi("getCboTypes");
        this.loadApi("getCboGrantType");
    };

    onSelectionChanged = ({ selectedRowsData }) => {
        const employeeSelected = _.get(selectedRowsData, "[0]", []);
        this.setState({ employeeSelected });
    };

    onOrderChanged = (e) => {
        if (!e || !e.column) return false;
        this.filter.fieldSort = e.column.dataField;
        this.filter.typeSort = e.typeSort;
        this.loadApi("getGrid");
    };

    renderFilters = () => {
        const { getCboGrantType, getCboTypes } = this.props;
        const { cboLoading, cboPeriodTime, PeriodTimeFrom, PeriodTimeTo } = this.state;
        const { TypeID, GrantTypeID } = this.filter;

        const iTo = _.findIndex(cboPeriodTime, (item) => item.PeriodTime === PeriodTimeTo.PeriodTime);

        const iFrom = _.findIndex(cboPeriodTime, (item) => item.PeriodTime === PeriodTimeFrom.PeriodTime);

        const cboPeriodTimeto = [...cboPeriodTime.filter((_, i) => iFrom !== -1 ? i <= iFrom : true)];

        const cboPeriodTimeFrom = [...cboPeriodTime.filter((_, i) => iTo !== -1 ? i >= iTo : true)];

        return (
            <Filter
                disabled={cboLoading}
                onTextChanged={this.onSearch}
                placeholder={Config.lang("DHR_Tim_kiem_che_do_bao_hiem")}
                onOpenLoaded={this.onLoadDataFilter}
                renderFilter={() => {
                    return (
                        <>
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <Combo
                                        loading={cboLoading}
                                        dataSource={getCboTypes}
                                        displayExpr={"TypeName"}
                                        showClearButton={true}
                                        valueExpr={"TypeID"}
                                        stylingMode={"outlined"}
                                        label={Config.lang("DHR_Loai_che_do")}
                                        onValueChanged={(e) => this.handleFilterChange("TypeID", e)}
                                        value={TypeID}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <Combo
                                        loading={cboLoading}
                                        dataSource={getCboGrantType}
                                        displayExpr={"GrantTypeName"}
                                        showClearButton={true}
                                        valueExpr={"GrantTypeID"}
                                        styli Mode={"outlined"}
                                        label={Config.lang("DHR_Che_do_tro_cap")}
                                        onValueChanged={(e) => this.handleFilterChange("GrantTypeID", e)}
                                        value={GrantTypeID}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} sm={6}>
                                    <Combo
                                        dataSource={cboPeriodTimeFrom}
                                        displayExpr={"PeriodTime"}
                                        valueExpr={"PeriodTime"}
                                        margin={"normal"}
                                        stylingMode={"outlined"}
                                        loading={cboLoading}
                                        value={PeriodTimeFrom.PeriodTime}
                                        shrink={true}
                                        placeholder={"MM/YYYY"}
                                        label={Config.lang("DHR_Tu")}
                                        onValueChanged={(e) => this.handleFilterChange("From", e)}
                                    />
                                </Col>
                                <Col xs={12} sm={6}>
                                    <Combo
                                        dataSource={cboPeriodTimeto}
                                        valueExpr={"PeriodTime"}
                                        displayExpr={"PeriodTime"}
                                        margin={"normal"}
                                        stylingMode={"outlined"}
                                        loading={cboLoading}
                                        value={PeriodTimeTo.PeriodTime}
                                        shrink={true}
                                        placeholder={"MM/YYYY"}
                                        label={Config.lang("DHR_Den")}
                                        onValueChanged={(e) => this.handleFilterChange("To", e)}
                                    />
                                </Col>
                            </Row>
                            <FormGroup
                                style={{ marginTop: 8 }}
                                className={"display_row align-center valign-middle"}
                            >
                                <ButtonGeneral
                                    name={Config.lang("DHR_Tim_kiem")}
                                    typeButton={"search"}
                                    size={"large"}
                                    color={"primary"}
                                    variant={"outlined"}
                                    onClick={() => this.loadApi("getGrid")}
                                />
                            </FormGroup>
                        </>
                    );
                }}
            />
        );
    };

    renderItem = (e) => {
        const { classes } = this.props;
        const { data } = e.row;
        const user = Config.getUser({ EmployeeID: data.EmployeeID });
        return (
            <div className={"display_row align-center"}>
                <Image className={classes.imgAvatar} src={user.UserPictureURL || Config.URlDef} />
                {data.EmployeeID}
            </div>
        );
    };

    render() {
        const { gridLoading, employeeSelected, expanded } = this.state;
        const { getGrid, classes } = this.props;
        const { skip, limit } = this.filter;
        return (
            <div>
                <FormGroup>
                    <ActionToolbar title={Config.lang("DHR_Che_do_bao_hiem_duoc_duyet")} />
                    <div className={"hidden"}>{this.renderFilters()}</div>
                    <FormGroup style={{ marginBottom: 0 }}>
                        <Row>
                            <Col xs={12} sm={12} md={12} l2g={12}>
                                <GridContainer
                                    itemPerPage={limit}
                                    skipPerPage={skip}
                                    loading={gridLoading}
                                    selection={{ mode: "single" }}
                                    style={{ border: "none" }}
                                    dataSource={_.get(getGrid,"rows", [])}
                                    showBorders={false}
                                    typePaging={"remote"}
                                    pagerFullScreen={false}
                                    isOrderAPI={true}
                                    totalItems={_.get(getGrid,"total",getGrid.length)}
                                    showColumnLines={false}
                                    hoverStateEnabled={true}
                                    onChangePage={this.onChangePage}
                                    onChangePerPage={this.onChangePerPage}
                                    onSelectionChanged={this.onSelectionChanged}
                                    onContentReady={(e) => {
                                        let grid = e.component;
                                        let selection = grid.getSelectedRowKeys();
                                        if (selection.length === 0) {
                                            grid.selectRowsByIndexes([0]);
                                        }
                                    }}
                                    onOrderChanged={this.onOrderChanged}
                                >
                                    <Column
                                        cellRender={this.renderItem}
                                        style={{ backgroundColor: "#b3bbc1" }}
                                        caption={Config.lang("DHR_Ma_NV")}
                                        dataField={"EmployeeID"}
                                        alignment={"center"}
                                        width={"145"}
                                    />
                                    <Column
                                        style={{ backgroundColor: "#b3bbc1" }}
                                        caption={Config.lang("DHR_Ten_nhan_vien")}
                                        dataField={"EmployeeName"}
                                        alignment={"left"}
                                        width={"150"}
                                    />
                                    <Column
                                        caption={Config.lang("DHR_Ngay_tu")}
                                        dataField={"DateFrom"}
                                        allowEditing={false}
                                        alignment={"center"}
                                        minWidth={"150"}
                                    />
                                    <Column
                                        caption={Config.lang("DHR_Ngay_den")}
                                        dataField={"DateTo"}
                                        allowEditing={false}
                                        alignment={"center"}
                                        minWidth={"150"}
                                    />
                                    <Column
                                        caption={Config.lang("DHR_Tien_bao_hiem")}
                                        dataField={"ApprovedAmount"}
                                        allowEditing={false}
                                        alignment={"left"}
                                        minWidth={"150"}
                                        cellRender={({ data = [] }) => data.ApprovedAmount.toLocaleString()}
                                    />
                                    <Column
                                        caption={Config.lang("DHR_Ghi_chu")}
                                        dataField={"Note"}
                                        allowEditing={false}
                                        minWidth={"250"}
                                    />
                                </GridContainer>
                            </Col>
                        </Row>
                    </FormGroup>
                </FormGroup>
                <ExpansionPanel expanded={expanded} onChange={() => this.setState({ expanded: !expanded })} className={classes.expansionPanel}>
                    <ExpansionPanelSummary className={classes.expansionPanelSummary} expandIcon={<ArrowDropDownIcon />}> <Typography style={{paddingLeft: 15}}>{Config.lang("DHR_Thong_tin_chi_tiet")}</Typography> </ExpansionPanelSummary>
                    <ExpansionPanelDetails style={{ padding: 0 }}>
                        <W21F2005FormDetail
                            employeeSelected={
                                _.isEmpty(employeeSelected) ? _.get(getGrid, "[0]", []) : employeeSelected
                            }
                        />
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </div>
        );
    }

  componentDidMount = async () => {
    await this.loadPermission();
    if (!this.state.iPermission) return;
    Api.put("/standard/load-cbo-period", { DivisionID: Config.getDivisionID() }).then(
      (res) => {
          if(_.get(res,"code", 100) !== 200) {
            Config.popup.show("INFO", _.get(res,"message",Config.lang("DHR_Loi_chua_xac_dinh")));
            return;
        };
        const data = _.get(res,"data",[]);
        const biggestMonth = ('0' + _.get(_.head(data), "TranMonth", 0)).slice(-2);
        const biggestYear = _.get(_.head(data), "TranYear", 0) + "";
        this.setState(
          {
            cboPeriodTime: data,
            PeriodTimeTo: {
              PeriodTime: `${biggestMonth}/${biggestYear}`,
              TranMonthTo: biggestMonth,
              TranYearTo: biggestYear,
            },
            PeriodTimeFrom: {
              PeriodTime:  `${biggestMonth}/${biggestYear}`,
              TranMonthFrom: biggestMonth,
              TranYearFrom: biggestYear,
            },
          },
          () => this.loadApi('getGrid')
        );
      }
    ).catch((e) => {console.log(e)})
  };
}

export default compose(
    connect(
        (state) => ({
            getCboGrantType: state.W21F2005.getCboGrantType,
            getCboTypes: state.W21F2005.getCboTypes,
            getGrid: state.W21F2005.getGrid,
        }),
        (dispatch) => ({
            generalActions: bindActionCreators(generalActions, dispatch),
            w21f2005Actions: bindActionCreators(w21f2005Actions, dispatch),
        })
    ),
    withStyles(styles)
)(W21F2005);
