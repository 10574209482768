/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 7/7/2020
 * @Example
 */
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import * as generalActions from "../../../../redux/general/general_actions";
import { bindActionCreators, compose } from "redux";
import Config from "../../../../config";
import { Col, Row, FormGroup } from "react-bootstrap";
import ButtonGeneral from "../../../common/button/button-general";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import { Column } from "devextreme-react/data-grid";
import GridContainer from "../../../grid-container/grid-container";
import Modal from "../../../common/modal/modal";
import {Checkbox} from "../../../common/form-material";
import Divider from "@material-ui/core/Divider";

class W09F6500 extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            loadingGridData: false,
            isSaving: false,
            dataGrid: [],
            selectedRowKeys: [],
            isChoose: true,
            isChecked: false,
        };
        this.tmpSelectedRowKeys = [];
        this.tmpSelectedRowData = [];
        this.departmentInit = [];
        this.changePage = false;

        this.selectedRowData = [];

        this.filter = {
            limit: 20,
            skip: 0,
            strSearch: "",
        };
        this.dataGridInit = [];
        this.isSelecting = false;

    }

    setLoading = (key, status = false) => {
        if (!key) return;
        this.setState({ ["loading" + key]: status });
    };

    componentDidMount() {
        this.loadGrid();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (JSON.stringify(prevProps.dataDepartment) !== JSON.stringify(this.props.dataDepartment)) {
            const {dataDepartment} = this.props;
            this.departmentInit = dataDepartment && dataDepartment.map(item => !!item.EvaluationVoucherID && item.DepartmentID);
            this.setState({
                selectedRowKeys : dataDepartment && dataDepartment.map(item =>  item.DepartmentID)
            });
        }
    }

    handleChange = (key, e) => {
        if (!key) return false;
        switch (key) {
            case "Search":
                this.filter.strSearch = e.target.value;
                break;
            case "WareHouseID":
                this.setState({ WareHouseID: e.value });
                break;
            default:
                break;
        }
    };

    loadGrid = () => {
        const { dataDepartment } = this.props;
        const params = {
            DivisionID: Config.getDivisionID()
        };
        this.setLoading('GridData', true);
        this.props.generalActions.getLoadDepartments(params, (errors, data) => {
            this.setLoading('GridData', false);
            if (errors) {
                let message = errors.message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
            let dataRowKey = [];
            if(dataDepartment.length > 0) {
                dataRowKey = dataDepartment.map(item => !!item.EvaluationVoucherID && item.DepartmentID);
            }
            this.dataGridInit = data;
            this.departmentInit = dataRowKey;
            this.setState({
                dataGrid: data,
                selectedRowKeys : dataRowKey
            });
        });
    };

    onFilter = () => {
        this.filter.skip = 0;
        this.loadGrid(true);
    };

    onSelect = () => {
        const { onChosen } = this.props;
        let selectedRowData = this.selectedRowData;

        if (selectedRowData && selectedRowData.length > 0) {
            if (onChosen) onChosen(selectedRowData);
        } else {
            Config.popup.show("INFO", Config.lang("DHR_Ban_chua_chon_du_lieu_tren_luoi"));
            return false;
        }
    };

    filterChange = (key, e) => {
        if (!key) return false;
        const { selectedRowKeys, dataGrid } = this.state;
        const myArrayFiltered = dataGrid.filter((el) => {
            return selectedRowKeys.some((f) => {
                return f === el.DepartmentID;
            });
        });

        switch (key) {
            case "isChecked":
                const checked = e.target.checked;
                this.setState({isChecked: checked});
                if(checked === true) {
                    this.setState({dataGrid: myArrayFiltered})
                } else {
                    this.setState({dataGrid: this.dataGridInit})
                }
                break;
            default:
                break;
        }
    };


    setSelectedEquipments = (e) => {
        const currentSelectedRowKeys =  e.currentSelectedRowKeys;
        const currentDeselectedRowKeys = e.currentDeselectedRowKeys;
        const { dataGrid } = this.state;

        if (currentDeselectedRowKeys.length > 0) {
            this.tmpSelectedRowKeys = this.tmpSelectedRowKeys.filter((e) => {
                return currentDeselectedRowKeys.indexOf(e) < 0;
            });
            this.tmpSelectedRowData = this.tmpSelectedRowData.filter((d) => {
                return currentDeselectedRowKeys.indexOf(d.DepartmentID) < 0;
            });
        }
        if (currentSelectedRowKeys.length > 0) {
            currentSelectedRowKeys.forEach((val) => {
                if (this.tmpSelectedRowKeys.indexOf(val) < 0) {
                    this.tmpSelectedRowKeys.push(val);
                }
            });
            dataGrid.forEach((val) => {
                const isExist = this.tmpSelectedRowData.filter((d) => d.DepartmentID === val.DepartmentID);
                if (this.tmpSelectedRowKeys.indexOf(val.DepartmentID) > -1 && isExist.length < 1) {
                    this.tmpSelectedRowData.push(val);
                }
            });
        }
    };

    onSelectionChanged = (e) => {
        const { mode } = this.props;
        if (!this.isSelecting) {
            this.isSelecting = true;
            const {currentDeselectedRowKeys} = e;
            const {selectedRowKeys} = this.state;
            let isInvalid = false;
            currentDeselectedRowKeys.forEach(deselected => {
                if(mode === "add") {
                    this.departmentInit = this.departmentInit.filter(e => {
                        return currentDeselectedRowKeys.indexOf(e) < 0;
                    })
                } else {
                    if (this.departmentInit.indexOf(deselected) > -1) {
                        isInvalid = true;
                    }
                }
            });
            if (isInvalid) {
                e.component.selectRows(selectedRowKeys);
                this.isSelecting = false;
                return;
            }

            // this.setSelectedEquipments(e);
            if (e.selectedRowKeys.length > 0) {
                this.setState({isChoose: false});
            }
            this.selectedRowData = e.selectedRowsData;
            this.setState({selectedRowKeys: e.selectedRowKeys}, () => {
                this.isSelecting = false;
            });
        }
    };

    render() {
        const { open, onClose, dataDepartment } = this.props;
        const { loadingGridData, isSaving, dataGrid, selectedRowKeys, isChoose, isChecked } = this.state;
        return (
            <React.Fragment>
                <Modal open={open} maxWidth={"lg"} fullWidth={true}>
                    <Modal.Title disableTypography>
                        <ActionToolbar
                            alignment={"flex-end"}
                            title={Config.lang("DHR_Danh_sach_phong_ban")}
                            showBorder={false}
                            upperCase={false}
                            className={"text-uppercase"}
                            style={{
                                marginTop: 0,
                            }}
                        >

                            <ButtonGeneral
                                name={Config.lang("DHR_Dong1")}
                                typeButton={"cancel"}
                                color={"default"}
                                variant={"outlined"}
                                disabled={isSaving}
                                className={"mgr5"}
                                style={{ textTransform: "uppercase" }}
                                size={"large"}
                                onClick={onClose}
                            />
                            <ButtonGeneral
                                name={Config.lang("DHR_Chon")}
                                color={"info"}
                                variant={"contained"}
                                loading={isSaving}
                                disabled={isChoose}
                                style={{ textTransform: "uppercase" }}
                                size={"large"}
                                onClick={this.onSelect}
                            />

                        </ActionToolbar>
                    </Modal.Title>
                    <Divider />
                    <Modal.Content>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <FormGroup>
                                    <GridContainer
                                        dataSource={dataGrid}
                                        showBorders={true}
                                        hoverStateEnabled={true}
                                        keyExpr="DepartmentID"
                                        scrolling={{mode:"virtual"}}
                                        loading={loadingGridData}
                                        selection={{
                                            allowSelectAll: false,
                                            mode: "multiple",
                                            // selectAllMode: "allPages",
                                            showCheckBoxesMode: "always"
                                        }}
                                        height={Config.getHeightGrid()}
                                        typeShort={window.innerWidth < 768}
                                        filterRow={{
                                            visible: true,
                                            showOperationChooser: false,
                                        }}
                                        typePaging={"remote"}
                                        selectedRowKey={selectedRowKeys}
                                        onSelectionChanged={this.onSelectionChanged}
                                        onEditorPreparing={(e) => {
                                            if (!e || !e.row) return;
                                            const { data } = e.row;
                                            const isOldDepartment = dataDepartment.find(d => !!d.EvaluationVoucherID && d.DepartmentID === data.DepartmentID);
                                            if (isOldDepartment) e.editorOptions.disabled = true;
                                        }}
                                    >
                                        <Column
                                            caption={Config.lang("DHR_Ma_phong_ban")}
                                            dataField={'DepartmentID'}
                                            alignment={"left"}
                                            width={"30%"}
                                            minWidth={"100px"}
                                        />
                                        <Column
                                            caption={Config.lang("DHR_Ten_phong_ban_VI")}
                                            dataField={'DepartmentNameU'}
                                            width={"40%"}
                                            minWidth={"200px"}
                                        />
                                        <Column
                                            caption={Config.lang("DRH_Ten_phong_ban_EN")}
                                            dataField={'DepartmentName01U'}
                                            width={"30%"}
                                            minWidth={"200px"}

                                        />
                                    </GridContainer>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Modal.Content>
                    <Modal.Content>
                        <ActionToolbar className={"mgt0"} alignment={"flex-end"}>
                            <div className={"display_row align-center align-between"} style={{width: '100%'}}>
                                <Checkbox
                                    checked={isChecked}
                                    value={true}
                                    size={"medium"}
                                    label={Config.lang('DHR_Chi_hien_thi_du_lieu_da_chon')}
                                    color={"primary"}
                                    onChange={(e) => this.filterChange('isChecked', e)}
                                />
                                <div/>
                            </div>
                        </ActionToolbar>
                    </Modal.Content>
                </Modal>
            </React.Fragment>
        );
    }
}

W09F6500.propTypes = {
    FormID: PropTypes.string,
    open: PropTypes.bool,
    mode: PropTypes.oneOf(['add','edit']),
    onClose: PropTypes.func,
    onChosen: PropTypes.func,
};

export default compose(
    connect(null,
        (dispatch) => ({
            generalActions: bindActionCreators(generalActions, dispatch),
        })
    ),
)(W09F6500);
