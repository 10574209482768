/**
 * @copyright 2022 @ DigiNet
 * @author THANHAN
 * @create 10/10/2022
 * @Example
 */

import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useDispatch } from "react-redux";
import Config from "../../../../config";
import { makeStyles } from "diginet-core-ui/theme";
import {
    Row,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    Typography,
    Dropdown,
    ButtonIcon,
    Checkbox,
    TextInput,
    DateRangePicker,
    Attachment
} from "diginet-core-ui/components";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import GridContainer from "../../../grid-container/grid-container";
import * as W13F1003Actions from "../../../../redux/W1X/W13F1003/W13F1003_actions";
import * as generalActions from "../../../../redux/general/general_actions";
// import "./W13F1003.scss";
import { Column } from "devextreme-react/data-grid";

const useStyles = makeStyles((theme) => ({
    col: {
        height: "100%",
    },
    borderLeft: {
        borderLeft: `1px solid ${theme.colors?.line?.system}`,
    },
    leftColItem: {
        marginRight: theme.spacing(6),
        [theme.breakpoints.down('md')]: {
            marginRight: 0
        }
    },
    actionToolbar: {
        marginLeft: -24,
        width: "unset",
        marginTop: "0px!important",
        backgroundColor: `${theme.colors.brand24}`,
        [theme.breakpoints.down('md')]: {
            marginLeft: 0,
        }
    },
    grid: {
        marginBottom: -theme.spacing(4),
        paddingBottom: theme.spacing(4),
    }
}))


const dataColumnGridExtra = () => [
    {
        caption: Config.lang('Ma_nhan_vien'),
        width: 150,
        dataField: 'EmployeeID',
        fixed: true,
        allowEditing: false
    },
    {
        caption: Config.lang('Ten_nhan_vien'),
        width: 200,
        dataField: 'EmployeeName',
        fixed: true,
        allowEditing: false
    },
    {
        caption: Config.lang('Du_an'),
        dataField: 'ProjectName',
        width: 300,
        allowEditing: false
    },
    {
        caption: Config.lang('Ky_luong'),
        dataField: 'Period',
        width: 100,
        allowEditing: false
    },
];

const FormID = "W13F1003";
const Language = Config.language || "84";

const W13F1003 = React.memo((props) => {
    const {
        open,
        onClose,
        iPermission,
    } = props;
    const classes = useStyles();
    const dispatch = useDispatch();
    let errorMessage = "";
    let counter = 0;

    const [dataFilter, setDataFilter] = useState(() => ({
        ProjectID: "",
        DepartmentID: "",
        DateFrom: null,
        DateTo: null,
    }));

    const [rangeDate, setRangeDate] = useState([]);
    const [dataGrid, setDataGrid] = useState(() => ({rows: [], total: 0}));
    const [dataAttachmentPopup, setDataAttachmentPopup] = useState([]);
    const [dataCboDepartments, setDataCboDepartments] = useState([]);
    const [dataCboProjects, setDataCboProjects] = useState(() => ({ rows: [], total: 0 }));
    const [cboDepartmentsLoading, setCboDepartmentsLoading] = useState(false);
    const [cboProjectsLoading, setCboProjectsLoading] = useState(false);
    const [gridLoading, setGridLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [isShowSelected, setIsShowSelected] = useState(false);
    const [showPopupAttachment, setShowPopupAttachment] = useState(false);

    const refGrid = useRef(null);
    const paramsPaging = useRef({
        skip: 0,
        limit: 20,
    });
    const filterCboProject = {
        strSearch: "",
        skip: 0,
        limit: 20
    };

    const isFilter = useRef(false);
    const refSelectedRowsData = useRef([]);
    const refSelectedRowKeys = useRef([]);
    const isSelectAll = useRef(false);
    const isChangePage = useRef(false);
    const isChangeShow = useRef(false);
    const timer = useRef(0);

    const [showDrawer, setShowDrawer] = useState(true);

    const loadGrid = (isLoadGrid = false) => {
        if (!isLoadGrid) {
            isChangePage.current = true;
        }
        const params = {
            FormID,
            Language,
            Employee: dataFilter?.EmployeeID ?? "",
            DepartmentID: dataFilter?.DepartmentID ? dataFilter?.DepartmentID.join(";") : "",
            ProjectID: dataFilter?.ProjectID ? dataFilter?.ProjectID.join(";") : "",
            DateFrom: rangeDate?.length > 0 ? rangeDate[0] : null,
            DateTo: rangeDate?.length > 0 ? rangeDate[1] : null,
            skip: paramsPaging.current.skip,
            limit: paramsPaging.current.limit,
        };
        setGridLoading(true);
        dispatch(W13F1003Actions.loadGrid(params, (error, data) => {
            setGridLoading(false);
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            data.rows.forEach(row => {
                row.ApprovalNotes = ""
            })
            setDataGrid({ rows: data?.rows || [], total: data?.total || 0 });
            if(isFilter.current) {
                isFilter.current = false;
            }
        }));
    };

    const loadCboDepartments = () => {
        const params = {
            HostID: "",
            FormID,
            Language,
            DivisionID: Config.getDivisionID(),
        };
        setCboDepartmentsLoading(true);
        dispatch(generalActions.getCboDepartments(params, (error, data) => {
            setCboDepartmentsLoading(false);
            if (error) {
                let message = error.message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
            setDataCboDepartments(data);
        }));
    };

    const loadCboProjects = (isReset) => {
        const param = {
            FormID,
            Language,
            skip: filterCboProject.skip,
            limit: filterCboProject.limit,
            search: filterCboProject.strSearch
        };
        setCboProjectsLoading(true);
        dispatch(generalActions.getCboProjects(param, (error, data) => {
            setCboProjectsLoading(false);
            if (error) {
                let message = error.message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
            const rows = data?.rows || [];
            const total = data?.total || 0;
            setDataCboProjects({
                rows: isReset ? rows : dataCboProjects.rows.concat(rows),
                total: total,
            });
        }));
    };

    const loadAttachments = (KeyID) => {
        if (!KeyID) return;
        const params = {
            KeyID: KeyID ? KeyID : "",
        }
        dispatch(generalActions.getAttachmentsByTransID(params, (error, data) => {
            if (error) {
                Config.popup.show("ERROR", error)
            }
            else if (data) {
                setDataAttachmentPopup(data);
            }
        }));
    };

    const setDataSelectedRowKeys = (e = {}) => {
        const {currentSelectedRowKeys, selectedRowsData} = e;
        let deselectRowsKey = [];
        currentSelectedRowKeys.map(key => {
            if(refSelectedRowKeys.current.indexOf(key) < 0) {
                e.component.byKey(key).done(item => {
                    // đk để bỏ chọn những dòng disabled
                        const rowData = selectedRowsData.find(f => f.AbsentVoucherID === key);
                        if(rowData) {
                            refSelectedRowsData.current.push(rowData);
                        }
                        refSelectedRowKeys.current.push(key);
                    
                });
            }
            return key;
        });
        if(deselectRowsKey.length > 0) {
            e.component.deselectRows(deselectRowsKey);
        }
    };

    const onSelectionChanged = (e) => {
        // khi change page khong cho DeselectedRowKeys
        if(isChangePage.current) {
            isChangePage.current = false;
            return false;
        }
        // case: khi có data SelectedRowKeys => changepage => click checkbox isShowSelected => changepage => thì khong cho DeselectedRowKeys
        if(isChangeShow.current) {
            isChangeShow.current = false;
            return false;
        }
        const {currentSelectedRowKeys, selectedRowKeys, currentDeselectedRowKeys} = e;
        const total = e.component.totalCount();
        // su ly SelectAll và deselectAll
        if ((selectedRowKeys.length === total || selectedRowKeys.length === 0) && isSelectAll.current) {
            if (selectedRowKeys.length === total) e.component.deselectAll();
            if (selectedRowKeys.length === 0) isSelectAll.current = false;
        }
        isSelectAll.current = selectedRowKeys.length && (selectedRowKeys.length + currentDeselectedRowKeys.length) === total;
        // su ly chon tung dong
        if(currentDeselectedRowKeys.length > 0) {
            currentDeselectedRowKeys.map(key => {
                if(refSelectedRowKeys.current.includes(key) && refSelectedRowsData.current.length > 0) {
                    refSelectedRowsData.current = refSelectedRowsData.current.filter(f => f.AbsentVoucherID !== key);
                    refSelectedRowKeys.current = refSelectedRowKeys.current.filter(f => f !== key);
                }
                return key;
            });
        }
        else if(currentSelectedRowKeys.length > 0) {
            setDataSelectedRowKeys(e);
        }
    };

    const onChangePage = (page) => {
        paramsPaging.current.skip = page * paramsPaging.current.limit;
        loadGrid();
    };

    const onChangePerPage = (perPage) => {
        paramsPaging.current.skip = 0;
        paramsPaging.current.limit = perPage;
        loadGrid(true);
    };

    const onAttachmentClick = (KeyID) => {
        setShowPopupAttachment(true);
        loadAttachments(KeyID);
    };

    const onLoadDataFilter = () => {
        loadCboDepartments();
        loadCboProjects();
    };

    const _onClose = () => {
        onClose && onClose();
    };

    const onClosePopupAttachment = () => {
        setShowPopupAttachment(false);
    };

    const resetData = () => {
        refSelectedRowsData.current = [];
        refSelectedRowKeys.current = [];
        isChangeShow.current = false;
        isChangePage.current = false;
        isSelectAll.current = false;
        isFilter.current = false;
        setIsShowSelected(false);
    };

    //true: Con key chua duoc select; false: da select het..
    const _checkSelectedRowsKeys = (dataSource) => {
        let isCheck = false;
        if (dataSource?.length > 0 && refSelectedRowKeys.current.length > 0) {
            const dataKeys = dataSource.map(d => d.AbsentVoucherID);
            for (let key of refSelectedRowKeys.current) {
                if (!dataKeys.includes(key)) {
                    isCheck = true;
                    break;
                }
            }
        }
        return isCheck;
    };

    const cellRenderAbsentTypeName = (cellData) => {
        if (!cellData) return null;
        const { data, column } = cellData;
        const dataField = column.dataField;
        const arrData = data[dataField].split(";");
        return (
            <div>
                {arrData.map((data, index) => {
                    return (
                        <Typography key={`AbsentType${index}`}>
                            {data}
                        </Typography>
                    )
                })}
            </div>
        )
    }

    const cellRenderPopupAttachment = (cellData) => {
        if (!cellData) return null;
        const { data, column } = cellData;
        const dataField = column.dataField;
        return (
            <div
                className="display_flex align-center valign-middle"
                style={{cursor:"pointer"}}
                onClick={() => onAttachmentClick(data?.AbsentVoucherID)}
            >
                <Typography color="primary">{`( ${data[dataField] || 0} )`}</Typography>
            </div>
        );
    };

    const _setDataFilter = (obj) => {
        setDataFilter({ ...dataFilter, ...obj });
    };

    const handleChange = (key, evt) => {
        if (!key || !evt) return;
        const value = evt?.value ?? evt?.target?.value ?? "";
        if (key === "RangePicker") {
            const DayFrom = Array.isArray(value) ? value[0] : null;
            const DayTo = Array.isArray(value) ? value[1] : null;
            setRangeDate([DayFrom, DayTo]);
        }
        else {
            _setDataFilter({ [key]: value });
        }
    };

    useEffect(() => {
        loadGrid();
        resetData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        onLoadDataFilter();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (refGrid.current){
            if (isShowSelected) refGrid.current.instance.filter('IsSelected','=',1);
            else refGrid.current.instance.filter('');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[isShowSelected]);

    let _dataGrid = dataGrid.rows;

    if(isShowSelected) {
        if (refSelectedRowsData.current.length > 0) {
            const selectedRows = refGrid.current && refGrid.current.instance.getSelectedRowKeys();
            _dataGrid = refSelectedRowsData.current;
            // kiem tra hiển thị những dòng đã check và reset các biến bằng false cho Deselected Row Keys ở hàm onSelectionChanged
            if(selectedRows.length === refSelectedRowKeys.current.length) {
                isChangeShow.current = false;
                isSelectAll.current = false;
            }
        }
    } else if(isChangeShow.current) {
        // kiem tra trường hợp reset isChangeShow.current khi changepage chưa chọn dòng trên lưới sau đó click rồi bỏ click checkbox isShowSelected
        if(!_checkSelectedRowsKeys(_dataGrid)) isChangeShow.current = false;
    }
    _dataGrid = _dataGrid.map(d => ({...d, IsSelected: d.IsSelected ?? 0}));

    const renderGrid = useMemo(() => {
        return (
            <GridContainer
                id={"gridContainer"}
                keyExpr={"AbsentVoucherID"}
                reference={ref => refGrid.current = ref}
                dataSource={_dataGrid}
                totalItems={dataGrid.total}
                itemPerPage={paramsPaging.current.limit}
                skipPerPage={paramsPaging.current.skip}
                editing={{
                    mode: "cell",
                    allowUpdating: true,
                    startEditAction: "click",
                }}
                selection={{
                    allowSelectAll: true,
                    mode: 'multiple',
                    selectAllMode: 'allPages',
                    showCheckBoxesMode: 'always',
                }}
                loading={gridLoading}
                pagerFullScreen={false}
                height={656}
                typePaging={"remote"}
                onSelectionChanged={onSelectionChanged}
                onChangePage={onChangePage}
                onChangePerPage={onChangePerPage}
                onContentReady={(e) => {
                    if (timer.current) clearTimeout(timer.current);
                    timer.current = setTimeout(() => {
                        e.component.selectRows(refSelectedRowKeys.current)
                    }, 600)
                }}
                onEditingStart={(e) => {
                    const isCheck = refSelectedRowKeys.current.includes(e?.data?.AbsentVoucherID);
                    if (!isCheck) e.cancel = true;
                }}
                onEditorPreparing={(e) => {
                    if (!e || !e.row) return;
                    if (
                        e.parentType === "dataRow" &&
                        e.dataField === "ApprovalNotes"
                    ) {
                        e.editorOptions.placeholder = Config.lang("Nhap_ghi_chu_duyet");
                    }
                    else {
                        e.editorOptions.onValueChanged = (evt) => {
                            e.row.data.IsSelected = +evt.value;
                            //refresh để chạy hàm filter lưới
                            e.component.refresh();
                        }
                    }
                }}
            >
                {dataColumnGridExtra().map((item, idx) => {
                    return (
                        <Column key={idx} {...item} />
                    )
                })}
                <Column
                    caption={`${Config.lang('Loai_thu_nhap')} - ${Config.lang('So_luong')}`}
                    cellRender={cellRenderAbsentTypeName}
                    dataField={'AbsentTypeName'}
                    width={300}
                    allowEditing={false}
                />
                <Column
                    caption={Config.lang('Ghi_chu')}
                    dataField={'Note'}
                    width={300}
                    allowEditing={false}
                />
                <Column
                    caption={Config.lang('Dinh_kem')}
                    dataField={'AttachNum'}
                    cellRender={cellRenderPopupAttachment}
                    width={100}
                    alignment={"center"}
                    allowEditing={false}
                />
                <Column
                    caption={Config.lang('Ghi_chu_duyet')}
                    dataField={'ApprovalNotes'}
                    width={300}
                />
                <Column
                    dataField={"AbsentVoucherID"}
                    visible={false}
                />
                <Column
                    dataField={"ApprovalFlowID"}
                    visible={false}
                />
                <Column
                    dataField={"ApprovalLevel"}
                    visible={false}
                />
            </GridContainer>
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_dataGrid, refSelectedRowKeys.current, gridLoading, isChangePage.current])

    const onSave = (mode) => {
        if (refSelectedRowsData.current.length > 0) {
            const confirmText = `Ban_co_chac_chan_muon_${mode === 0 ? "duyet" : "tu_choi"}`
            Config.popup.show(
                "YES_NO", // type
                Config.lang(confirmText), // message
                () => { // chon Dong y
                    setGridLoading(true);
                    setIsSaving(true);
                    const approvalStatus = mode === 0 ? "1" : "100";
                    const arrCheckParams = [];
                    const arrApprovalParams = [];
                    refSelectedRowsData.current.forEach((data) => {
                        const {
                            AbsentVoucherID,
                            ApprovalLevel,
                            ApprovalFlowID,
                            ApprovalNotes
                        } = data;
                        const checkParams = {
                            FormID: "W13F1000",
                            AbsentVoucherID,
                            Mode: mode,
                        }
                        const approvalParams = {
                            FormID: "W13F1000",
                            AbsentVoucherID,
                            ApprovalLevel,
                            ApprovalFlowID,
                            ApprovalStatus: approvalStatus,
                            ApprovalsNotes: ApprovalNotes,
                            Mode: 0,
                        };
                        arrCheckParams.push(checkParams);
                        arrApprovalParams.push(approvalParams);
                    });
                    let arrParams = { arrCheckParams, arrApprovalParams };
                    const checkParamsLength = arrParams.arrCheckParams.length;
                    const approvalTimeout = checkParamsLength < 5 ? 1000 : Math.ceil(checkParamsLength /10) * 1500;
                    dispatch(W13F1003Actions.check(arrParams, (error, data) => {
                        counter++;
                        const isLastRequest = (counter === arrParams.arrCheckParams.length);
                        const lastRequestHanlder = (data) => {
                            if (isLastRequest) {
                                if (errorMessage) {
                                    const lastMessage = Config.lang(`Ton_tai_nhung_don_tu_${mode === 0 ? "duyet" : "tu_choi"}_khong_thanh_cong:`) + `${errorMessage}\n${data.Message}`;
                                    setTimeout(() => Config.popup.show("INFO", lastMessage, () => {
                                        arrParams = [];
                                        loadGrid();
                                        resetData();
                                    }),approvalTimeout);
                                }
                                else {
                                    setTimeout(() => Config.popup.show("INFO", Config.lang(`${mode === 0 ? "Duyet" : "Tu_choi"}_thanh_cong`), () => {
                                        arrParams = [];
                                        loadGrid();
                                        resetData();
                                    }),approvalTimeout);
                                }
                            }
                        }
                        if (isLastRequest) {
                            setTimeout(()=> {
                                setIsSaving(false);
                                setGridLoading(false);
                            },approvalTimeout);
                        }
                        if (error) {
                            Config.popup.show("ERROR", error);
                            return false;
                        }
                        else if (data.Status === 1) {
                            if (data.MsgAsk === 0) {
                                errorMessage = `${errorMessage}\n${data.Message}`; // MsgAsk = 0 thì UI giữ lại để sau khi thực hiện hết quy trình sẽ xuất ra Message = Message của các dòng có Status = 1 Và MsgAsk = 0 cộng lại cách nhau bởi dấu xuống dòng
                                if (isLastRequest) {
                                    const lastMessage = Config.lang(`Ton_tai_nhung_đon_tu_${mode === 0 ? "duyet" : "tu_choi"}_khong_thanh_cong:`) + `${errorMessage}\n${data.Message}`;
                                    Config.popup.show("INFO", lastMessage, () => {
                                        arrParams=[];
                                        loadGrid();
                                        resetData();
                                    });
                                }
                            }
                            else if (data.MsgAsk === 1) {
                                Config.popup.show("YES_NO", data?.Message, () => {
                                    (async () => {
                                        dispatch(W13F1003Actions.approval(arrParams.arrApprovalParams[counter - 1], error => {
                                            if (error) {
                                                Config.popup.show("ERROR", error);
                                            }
                                            lastRequestHanlder(data);
                                        }))
                                    })();
                                },() => {
                                    dispatch(W13F1003Actions.cancelApproval(() => {
                                        lastRequestHanlder(data);
                                    }))
                                });
                            }
                        }
                        else {
                            lastRequestHanlder(data);
                        }
                    }));
                }, () => { // chon Huy
                })
        }
        else {
            Config.popup.show("INFO", Config.lang("Ban_chua_chon_nhan_vien"));
        }
    };

    return (
        <Modal width={1280} open={open} onClose={_onClose}>
            <Modal
                width={"70%"}
                open={showPopupAttachment}
                onClose={onClosePopupAttachment}
                alignment="center"
            >
                <ModalHeader/>
                <ModalBody>
                    <Attachment
                        data={dataAttachmentPopup}
                        label={Config.lang("Dinh_kem")}
                        readOnly
                    />
                </ModalBody>
            </Modal>
            <ModalHeader>
                {Config.lang("Duyet_dieu_chinh_thu_nhap_hang_loat")}
            </ModalHeader>
            <ModalBody>
                <Row>
                    <Col xs={12} sm={12} md={showDrawer ? 4 : 1}>
                        <Row>
                            <Col xs={12} sm={12} md={12}>
                                <ActionToolbar
                                    alignment={showDrawer ? "space-between" : "center"}
                                    showBorder={false}
                                    upperCase={false}
                                    className={classes.actionToolbar}
                                    allwaysTop={false}
                                >
                                    <div
                                        className={"display_row align-center valign-middle"}
                                    >
                                        <ButtonIcon
                                            name="FilterFilled"
                                            viewType="text"
                                            circular
                                            color="dark"
                                            size="small"
                                            onClick={() => setShowDrawer(!showDrawer)}
                                        />
                                        {showDrawer &&
                                            <Typography>{Config.lang("Tieu_chi_loc")}</Typography>
                                        }
                                    </div>
                                    {showDrawer &&
                                        <ButtonIcon
                                            name="ArrowLeft"
                                            circular
                                            viewType="text"
                                            onClick={() => setShowDrawer(false)}
                                        />
                                    }
                                </ActionToolbar>
                            </Col>
                            {showDrawer &&
                                <>
                                    <Col xs={12} sm={12} md={12}>
                                        <Checkbox
                                            style={{ marginTop: 2 }}
                                            checked={isShowSelected}
                                            onChange={e => {
                                                isChangeShow.current = true;
                                                if(isChangePage.current) {
                                                    isChangePage.current = false;
                                                }
                                                setIsShowSelected(e.value);
                                            }}
                                            disabled={isSaving || gridLoading}
                                            label={Config.lang("Chi_hien_thi_nhung_du_lieu_da_chon")}
                                        />
                                    </Col>
                                    <Col xs={12} sm={12} md={12}>
                                        <TextInput
                                            label={Config.lang("Nhan_vien")}
                                            className={classes.leftColItem}
                                            startIcon={"search"}
                                            viewType="outlined"
                                            placeholder={Config.lang("Nhap_ma_nhan_vien_ten_nhan_vien")}
                                            onChange={e => handleChange("EmployeeID", e)}
                                        />
                                    </Col>
                                    <Col xs={12} sm={12} md={12}>
                                        <Dropdown
                                            className={classes.leftColItem}
                                            allowSearch
                                            clearAble
                                            multiple
                                            dataSource={dataCboProjects.rows}
                                            total={dataCboProjects.total}
                                            loading={cboProjectsLoading}
                                            value={dataFilter.ProjectID}
                                            viewType={"outlined"}
                                            displayExpr={'{ProjectID} - {ProjectName}'}
                                            keyExpr={'ProjectID'}
                                            valueExpr={'ProjectID'}
                                            label={Config.lang("Du_an")}
                                            placeholder={Config.lang("Chon")}
                                            onChange={e => handleChange("ProjectID", e)}
                                            onInputChanged={(e) => {
                                                filterCboProject.strSearch = e.target.value;
                                                filterCboProject.skip = 0;
                                                loadCboProjects(true);
                                            }}
                                            onLoadMore={(e) => {
                                                filterCboProject.skip = e.skip;
                                                filterCboProject.limit = e.limit;
                                                loadCboProjects();
                                            }}
                                        />
                                    </Col>
                                    <Col xs={12} sm={12} md={12}>
                                        <Dropdown
                                            className={classes.leftColItem}
                                            allowSearch
                                            clearAble
                                            multiple
                                            dataSource={dataCboDepartments}
                                            loading={cboDepartmentsLoading}
                                            value={dataFilter.DepartmentID}
                                            viewType={"outlined"}
                                            displayExpr={'{DepartmentID} - {DepartmentName}'}
                                            keyExpr={'DepartmentID'}
                                            valueExpr={'DepartmentID'}
                                            label={Config.lang("Phong_ban")}
                                            placeholder={Config.lang("Chon")}
                                            onChange={e => handleChange("DepartmentID", e)}
                                        />
                                    </Col>
                                    <Col xs={12} sm={12} md={12}>
                                        <DateRangePicker
                                            className={classes.leftColItem}
                                            controls
                                            clearAble
                                            value={rangeDate}
                                            viewType={"outlined"}
                                            label={Config.lang("Ngay_lap_de_xuat")}
                                            onChange={evt => handleChange("RangePicker", evt)}
                                            placeholder={"dd/mm/yyyy - dd/mm/yyyy"}
                                            returnFormat={"YYYY-MM-DD"}
                                        />
                                    </Col>
                                    <Col xs={12} sm={12} md={12}
                                        className={"display_row valign-bottom"}
                                    >
                                        <Button
                                            color={"primary"}
                                            className={`${classes.leftColItem} mgt2x`}
                                            startIcon={"search"}
                                            viewType={"outlined"}
                                            label={Config.lang("Tim_kiem")}
                                            onClick={()=>{
                                                isFilter.current = true;
                                                loadGrid();
                                            }}
                                        />
                                    </Col>
                                </>
                            }
                        </Row>
                    </Col>
                    <Col xs={12} sm={12} md={showDrawer ? 8 : 11} className={`${classes.col} ${classes.borderLeft}`}>
                        {renderGrid}
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button
                    disabled={iPermission < 2}
                    startIcon={"Approval"}
                    viewType={"filled"}
                    label={Config.lang("Duyet")}
                    color={"success"}
                    className={"mgr2x"}
                    onClick={() => onSave(0)}
                />
                <Button
                    disabled={iPermission < 2}
                    startIcon={"Cancel"}
                    viewType={"filled"}
                    label={Config.lang("Tu_choi")}
                    color={"danger"}
                    onClick={() => onSave(1)}
                />
            </ModalFooter>
        </Modal>
    )
});

export default W13F1003;