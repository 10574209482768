
import _ from "lodash";
import React                                       from "react";
import {Dropdown} from "diginet-core-ui/components";
import {FormGroup, Col, Row}                                 from "react-bootstrap";
import Config                                      from "../../../../config";
import ActionToolbar                               from "../../../common/toolbar/action-toolbar";
import {bindActionCreators, compose}               from "redux";
import {connect}                                   from "react-redux";
import {withStyles, Tabs, Tab}                     from "@material-ui/core";
import * as generalActions                         from "../../../../redux/general/general_actions";
import * as W09F4000Actions                        from "../../../../redux/W0X/W09F4000/W09F4000_actions";
import TabContent, {TabPanel}                      from "../../../common/tabs/tab-content";
import ButtonGeneral        from "../../../common/button/button-general";
import OrgChart             from './OrgChart';
import Filter               from "../../../filter/filter";
import './W09F4000.scss';
import '../../../libs/org-chart/orgchart-webcomponents.scss';
import ItemOrgChart         from './ItemOrgChart';
import ItemPositionChart    from "./ItemPositionChart";
import ReactDOM             from "react-dom";
import W09F4001Popup        from "./W09F4001Popup";
import Icons from "../../../common/icons/";
import {Combo} from "../../../common/form-material";
import {Checkbox, Popover, ButtonIcon} from 'diginet-core-ui/components'
import {useTheme} from 'diginet-core-ui/theme';

const styles = {

};

const {colors, colors:{white}} = useTheme();

class W09F4000 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            iPermission: 0,
            tab: 0,
            OrgChartID: "",
            DutyID: "",
            showW09F4001Popup: false,
            itemDataNode: null,
            isHiddenW09F4010: false,
            loading: false,
            isExpandedOrgChart: false,
            isExpandedOrgChartPosition: false,
            dataCboEmployees: {
                rows: [],
                total: 0,
            },
            loadingCboEmployee: false,
            listDutyFilter:[],
            showAvatar: false,
            colorOrg: 'formal'
        };
        this.filter = {
            skip: 0,
            limit: 10
        };
        this.refOrgChart = null;
        this.refOrgChartPosition = null;
        this.defaultDepth = 2;
    }

    loadPermission = async () => {
        await this.props.generalActions.getPermission("W09F4000", (isPer) => {
            this.setState({iPermission: isPer});
        });
    };

    componentDidMount = async () => {
       await this.loadPermission();
       this.loadCboDuty();
        this.loadListCboPosition();
    };

    loadCboDuty = () => {
        this.setState({loading: true});
        this.props.generalActions.getCboDuty2((error) => {
            this.setState({loading: false});
            if (error) {
                let message = error.message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show('ERROR', message);
                return false;
            }
        });
    };

    /**
     * function loadFilter
     * @param params
     * @param cb
     * @param close {boolean} - flag close filter (true if close)
     */
    loadFilter= (params, cb, close) => {
        const {listDutyFilter} = this.state;
        let listDutyFilterT = [...listDutyFilter];
        if(close){
            listDutyFilterT = listDutyFilterT.filter(i=>i !== params.DutyID);
            this.setState({
                listDutyFilter: listDutyFilterT
            });
        }
        else{
            this.props.w09f4000Actions.loadFilter(params, (error, data) => {
                if (error) {
                    let message = error.message || Config.lang("DHR_Loi_chua_xac_dinh");
                    Config.popup.show('ERROR', message);
                    return false;
                }
                else if(cb){
                    if(!listDutyFilterT.includes(params.DutyID)) listDutyFilterT.push(params.DutyID);
                    this.setState({
                        listDutyFilter: listDutyFilterT
                    });
                    cb(data);
                }
            });
        }
    };

    loadListCboPosition = () => {
        this.setState({loading: true});
        this.props.w09f4000Actions.getListCboPosition({Language:Config.language || "84"}, (error) => {
            this.setState({loading: false});
            if (error) {
                let message = error.message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show('ERROR', message);
                return false;
            }
        });
    };

    handleChange = (key,e) => {
      if(!key || !e) return false;
        switch (key) {
            case "DutyID":
                this.setState({ DutyID : e.value });
                break;
            case "OrgChartID":
                this.setState({ OrgChartID : e.value });
                break;
            case "EmployeeID":
                this.setState({ EmployeeID : e.value });
                break;
            default:
                break;
        }

    };

    onChangeTab = (e,value) => {
        this.setState({tab: value});
    };

    loadCboEmployees = (isReset = false) => {
        const {dataCboEmployees} = this.state;
        const params = {
            ...this.params,
            Type: "EmployeeID",
            skip: this.filter.skip,
            limit: this.filter.limit,
            search: this.filter.strSearch
        };
        this.setState({loadingCboEmployee: true});
        this.props.generalActions.getCboEmployees(params, (err, data) => {
            this.setState({loadingCboEmployee: false});
            if (err) {
                Config.popup.show("ERROR", err);
                return false;
            }
            if (data) {
                const rows = _.get(data, "rows", data);
                const total = _.get(data, "total", _.get(data, "length", 0))
                this.setState({
                    dataCboEmployees: {
                        rows: isReset ? rows : dataCboEmployees.rows.concat(rows),
                        total
                    }
                })
            }
        });
    };

    onFilter = () => {
        this.loadCboEmployees();
    };

    renderFilters = () => {
        const { getCboDuty, getDataCboPosition } = this.props;
        const {OrgChartID, DutyID, tab, dataCboEmployees, loadingCboEmployee, EmployeeID} = this.state;
            return (
                <>
                    <Filter
                        isUseDDCore
                        onOpened={this.onFilter}
                        onTextChanged={this.onSearch}
                        placeholder={Config.lang("DHR_Noi_dung_tim_kiem")}
                        renderFilter={() => {
                            return (
                                <>
                                    {tab === 1 && (
                                        <FormGroup>
                                            <Combo
                                                dataSource={Config.storeDataSoureDevExtreme(getCboDuty)}
                                                showClearButton={true}
                                                displayExpr={'DutyName'}
                                                valueExpr={"DutyID"}
                                                stylingMode={'outlined'}
                                                label={Config.lang('DHR_Chuc_vu')}
                                                value={DutyID}
                                                onValueChanged={(e) => this.handleChange("DutyID", e)}
                                            />
                                        </FormGroup>
                                    )}
                                    {tab === 0 && (
                                        <FormGroup>
                                            <Row>
                                                <Col xs={12} sm={12} md={12} lg={12}>
                                                    <Combo
                                                        dataSource={Config.storeDataSoureDevExtreme(getDataCboPosition)}
                                                        showClearButton={true}
                                                        displayExpr={'OrgChartName'}
                                                        valueExpr={"OrgChartID"}
                                                        stylingMode={'outlined'}
                                                        label={Config.lang('DHR_Co_cau_to_chuc')}
                                                        value={OrgChartID}
                                                        onValueChanged={(e) => this.handleChange("OrgChartID", e)}
                                                    />
                                                </Col>
                                                <Col xs={12} sm={12} md={12} lg={12}>
                                                    <Dropdown
                                                        allowSearch
                                                        dataSource={dataCboEmployees.rows}
                                                        total={dataCboEmployees.total}
                                                        skip={this.filter.skip}
                                                        limit={this.filter.limit}
                                                        displayExpr={'{EmployeeID} - {EmployeeName}'}
                                                        valueExpr={'EmployeeID'}
                                                        value={EmployeeID}
                                                        loading={loadingCboEmployee}
                                                        viewType={'outlined'}
                                                        clearAble
                                                        label={Config.lang('Nhan_vien')}
                                                        placeholder={Config.lang('Chon')}
                                                        renderSelectedItem={'EmployeeName'}
                                                        onChange={e => this.handleChange("EmployeeID", e)}
                                                        onInput={(e) => {
                                                            const value = e.target.value;
                                                            if (this.timer) clearTimeout(this.timer);
                                                            this.timer = setTimeout(() => {
                                                                this.filter.strSearch = value;
                                                                this.filter.skip = 0;
                                                                this.loadCboEmployees(true);
                                                            }, 500);

                                                        }}
                                                        onLoadMore={(e) => {
                                                            this.filter.skip = e.skip;
                                                            this.filter.limit = e.limit;
                                                            this.loadCboEmployees();
                                                        }}
                                                        style={{ margin: 0 }}
                                                    />
                                                </Col>
                                            </Row>
                                        </FormGroup>
                                    )}

                                    <FormGroup className={"display_row align-center valign-middle"}>
                                        <ButtonGeneral
                                            name={Config.lang("DHR_Tim_kiem")}
                                            typeButton={"search"}
                                            size={"large"}
                                            color={"primary"}
                                            variant={"outlined"}
                                            onClick={this.loadDataFormOrgChart}
                                        />
                                    </FormGroup>
                                </>
                            );
                        }}
                    />
                </>
            )
    };

    onRefesh = (depth, refreshShowAvatar, tabCustom) => {
        let {tab} = this.state;
        if(tabCustom) tab = tabCustom;
        if (tab === 1) {
            if (this.refOrgChartPosition) {
                this.setState({DutyID: ""}, () => {
                    this.refOrgChartPosition.refreshChart(depth);
                });
            }

            this.setState({isExpandedOrgChartPosition: !this.state.isExpandedOrgChartPosition}, () => {
                if(depth === 2) {this.setState({isExpandedOrgChartPosition: false})}
            });
        } else {
            if (this.refOrgChart) {
                this.setState({OrgChartID: "", EmployeeID: ""}, () => {
                    this.refOrgChart.refreshChart(depth);
                });
            }

            if(refreshShowAvatar) return;
            this.setState({isExpandedOrgChart: !this.state.isExpandedOrgChart}, () => {
                if(depth === 2) {this.setState({isExpandedOrgChart: false})}
            });
        }

    };

    renderItemOrgChart = (node, data, orgchart) => {
        const {showAvatar, colorOrg} = this.state;
        const child = React.createElement(ItemOrgChart, {
            node: node,
            data: data,
            onW09F4001Popup: this.showW09F4001Popup,
            orgchart:    orgchart,
            showAvatar:    showAvatar,
            colorOrg: colorOrg
        }, null);
        ReactDOM.render(child, node);
    };

    renderItemOrgChartPostion = (node, data, orgchart) => {
        const {colorOrg} = this.state;
        const child = React.createElement(ItemPositionChart, {
            node: node,
            data: data,
            onW09F4001Popup: this.showW09F4001Popup,
            orgchart:    orgchart,
            loadFilter:this.loadFilter,
            listDutyFilter: this.state.listDutyFilter,
            isExpanded: this.state?.isExpandedOrgChartPosition,
            colorOrg: colorOrg
        }, null);
        ReactDOM.render(child, node);
    };

    showW09F4001Popup = (data) => {
        const {tab} = this.state;
        this.setState({
            isHiddenW09F4010: tab === 1,
            itemDataNode: data,
            showW09F4001Popup: true
        });
    };
    onCloseW09F4001Popup = () => {
        this.setState({
            showW09F4001Popup: false
        });
    };

    loadDataFormOrgChart = () => {
        const {tab} = this.state;
        if (tab === 1) {
            if (this.refOrgChartPosition) {
                this.refOrgChartPosition.refreshChart();
            }
        } else {
            if (this.refOrgChart) {
                this.refOrgChart.refreshChart();
            }
        }
    };

    render() {
        const {iPermission, tab, OrgChartID, showW09F4001Popup, itemDataNode, isHiddenW09F4010, isExpandedOrgChart, isExpandedOrgChartPosition, DutyID, EmployeeID, showAvatar, colorOrg} = this.state;
        if (!iPermission) return null;

        const obSearch ={};
        if(EmployeeID && tab === 0) obSearch.EmployeeID = obSearch.RootEmployeeID = EmployeeID;
        if(OrgChartID && tab === 0) obSearch.OrgChartID = OrgChartID;
        if(DutyID && tab === 1) obSearch.DutyID = DutyID;

        const isExpanded = (tab === 0 && isExpandedOrgChart) || (tab === 1 && isExpandedOrgChartPosition);

        return (
            <React.Fragment>
                <div className={"hidden"}>{this.renderFilters()}</div>

                {showW09F4001Popup && (
                    <W09F4001Popup
                        open={showW09F4001Popup}
                        title={Config.lang("DHR_Danh_sach_nhan_vien")}
                        data={itemDataNode}
                        isHiddenW09F4010={isHiddenW09F4010}
                        onClose={this.onCloseW09F4001Popup}
                    />
                )}
                <FormGroup>
                    <ActionToolbar
                    >
                        <div className={"display_row align-center align-between"} style={{width: '100%'}}>
                            <div className={"display_row align-left"}>
                                <Tabs
                                    indicatorColor={"primary"}
                                    textColor={"primary"}
                                    value={tab}
                                    onChange={this.onChangeTab}
                                >
                                    <Tab style={{minWidth: 70, minHeight: 42}} value={0} label={Config.lang("DHR_Co_cau_to_chuc")} />
                                    <Tab style={{minWidth: 70, minHeight: 42}} value={1} label={Config.lang("DHR_Co_cau_chuc_danh")} />
                                </Tabs>
                            </div>

                            <div className={"display_row align-center valign-bottom "}>
                                <ButtonIcon
                                    onClick={e => {
                                        if(!this.refPopover) return null;
                                        this.refPopover.setPosition(e.currentTarget);
                                        this.refPopover.show();
                                    }}
                                    viewType={'filled'}
                                    color={colors[`${colorOrg}5`]}
                                    name={'Colors'}
                                    className={'mgr5'}
                                    width={40}
                                    height={40}
                                />
                                <ButtonGeneral
                                    variant={"contained"}
                                    color={"primary"}
                                    name={Config.lang("DHR_Lam_Moi")}
                                    icon={<Icons className="fas fa-sync" style={{fontSize:14}}/>}
                                    style={{textTransform: "uppercase"}}
                                    onClick={() => this.onRefesh(this.defaultDepth)}
                                />
                                <ButtonGeneral
                                    variant={"contained"}
                                    className={"mgl10"}
                                    color={"primary"}
                                    typeButton={ isExpanded ? "Minimize" : "Maximize"}
                                    name={  isExpanded ? Config.lang("DHR_Thu_gon")  : Config.lang("DHR_Mo_tat_ca")}
                                    style={{textTransform: "uppercase"}}
                                    onClick={() => this.onRefesh( isExpanded ? this.defaultDepth : "")}
                                />
                                <Popover
                                    ref={refs=>this.refPopover = refs}
                                    width={240}
                                    anchorOrigin={{
                                        vertical : "bottom",
                                        horizontal : "bottom"
                                    }}
                                    transformOrigin={{
                                        vertical: "right",
                                        horizontal: "right"
                                    }}
                                >
                                    <ColorPicker onChangeColor={vl => {
                                        this.setState({colorOrg: vl});
                                        this.onRefesh(isExpandedOrgChart ? "" : this.defaultDepth, true, 0);
                                        this.onRefesh(isExpandedOrgChartPosition ? "" : this.defaultDepth, true, 1);
                                        this.refPopover.close();
                                    }}/>
                                </Popover>
                            </div>
                        </div>
                    </ActionToolbar>
                    {!tab &&
                        <ActionToolbar allwaysTop={false}
                                       height={40}
                                       style={{ margin: '0 -15px', width: 'calc(100% + 30px)' }}
                        >
                            <div className={'display_row align-center'}>
                                <Checkbox checked={showAvatar}
                                          onChange={e => {
                                              this.setState({ showAvatar: e.value });
                                              this.onRefesh(isExpanded ? "" : this.defaultDepth, true)
                                          }}
                                          label={Config.lang("Hien_thi_dai_dien_co_cau_to_chuc")}
                                />
                            </div>
                        </ActionToolbar>
                    }
                </FormGroup>
                <TabContent
                    style={{backgroundColor: "#F4F4F4"}}
                    activeKey={tab}
                    disableSwipe={true}
                    lazyLoading={false}
                    // className={classes.tabContent}
                >
                   <TabPanel index={0}>
                       <OrgChart
                           ref={ref => this.refOrgChart = ref}
                           FormID={"W09F4000"}
                           id={"org-chart-container"}
                           depth={this.defaultDepth}
                           valueExpr={"OrgChartID"}
                           displayExpr={"OrgChartName"}
                           parentIDExpr={"OrgChartParentID"}
                           childrenExpr={"OrgCharts"}
                           renderItem={this.renderItemOrgChart}
                           tabID={tab}
                           search={obSearch}
                       />
                    </TabPanel>
                    <TabPanel index={1}>
                        <OrgChart
                            ref={ref => this.refOrgChartPosition = ref}
                            FormID={"W09F4010"}
                            id={"position-chart-container"}
                            depth={this.defaultDepth}
                            valueExpr={"DutyID"}
                            displayExpr={"DutyName"}
                            parentIDExpr={"DutyManagerID"}
                            childrenExpr={"DutyGroup"}
                            renderItem={this.renderItemOrgChartPostion}
                            tabID={tab}
                            search={obSearch}
                        />
                    </TabPanel>
                </TabContent>
            </React.Fragment>
        );
    }

}


const ColorPicker = (props) => {
    const listColor = ["info", "success", "warning", "danger","formal"];
    const {onChangeColor} = props;
    return (
        <div className={'display_row align-center valign-middle pd10'}>
            {listColor.map(i => {
                return (
                    <div key={i}
                         style={{
                             border: `1px solid ${white}`,
                             background: colors[`${i}5`],
                             width: 40,
                             height: 40
                         }}
                         onClick={()=>{onChangeColor(i)}}
                    />
                );
            })}
        </div>
    );
};

export default compose(connect(state => ({
        getCboDuty: state.general.getCboDuty2,
        getDataCboPosition: state.W09F4000.getDataCboPosition,
    }),
    (dispatch) => ({
        w09f4000Actions: bindActionCreators(W09F4000Actions, dispatch),
        generalActions: bindActionCreators(generalActions, dispatch),
    })
), withStyles(styles, {withTheme: true}))(W09F4000);
