
import React                             from "react";
import { withStyles } from "@material-ui/core";
import { useTheme } from "diginet-core-ui/theme";
import { Typography, Avatar, Tooltip } from 'diginet-core-ui/components'
import Config from '../../../../config';

const coreTheme = useTheme();
const { spacing, colors, colors: {white, system:{rest}, dark, dark6} } = coreTheme;

const styles = {
    wrapperItem: {
        position:'relative',
        boxShadow:'0px 2px 2px rgba(0, 0, 0, 0.25)',
        borderRadius: 4,
        minWidth:200,
        width: 240,
        backgroundColor: white,
        '&:hover':{
            boxShadow:'0px 4px 4px rgba(0, 0, 0, 0.25)'
        },
        '& .overflow-one-line':{
            whiteSpace:'pre !important',
            maxWidth:'100% !important'
        }
    },
    footerItem: {
        display: 'flex',
        textAlign:'left',},
    layerImg: {
        top: 45,
        left: '50%',
        transform: 'translate(-50%, 0)',
        zIndex: 1,
        position: 'absolute',
    },
    nodeNumber: {
        position: 'absolute',
        bottom: -14,
        minWidth: 28,
        height: 28,
        zIndex: 1,
        borderRadius: '50%',
        border:`2px solid ${white}`,
        cursor: "pointer",
        left: 'calc(50% - 14px)',
        display:'flex !important',
        boxShadow:'0px 2px 2px rgba(0, 0, 0, 0.25)'
    },
    styleOrgChartName: {
        display:'flex !important',
        alignItems:'flex-start',
        justifyContent: 'center',
        width: '100%'
    },
    styleOverFlowText: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        display:'block'
    },
    styleTooltip: {
        fontSize: 14
    },
    cursorPointer: {
        cursor: "pointer"
    },
    showMoreUser:{
        backgroundColor: rest,
        width: 40,
        minWidth: 40,
        height: 40,
        display: "flex !important",
        alignItems: "center !important",
        justifyContent: "center",
        color: white,
        borderRadius: 20,
        cursor: 'pointer'
    },

};

class ItemOrgChart extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            iPermission: 0,
            tab: 0,
            showW09F4001Popup: false,
            itemDataNode: null,
            isHiddenW09F4010: false
        };
    }

    showPopup = () => {
        const {data, onW09F4001Popup} = this.props;
        if (onW09F4001Popup) onW09F4001Popup(data);
    };

    toggleShowItem = (e) => {
        e.stopPropagation();
        const {node, data, orgchart} = this.props;
        if (orgchart && data.countChildren > 0) {
            const nodeState = orgchart._getNodeState(node, "children");

            if (nodeState.exist && nodeState.visible) {
                orgchart.hideChildren(node);
                this._scrollToNode(node, orgchart);
            } else {
                orgchart.showChildren(node);
                this._scrollToNode(node, orgchart, 400);
            }
        }
    };

    _scrollToNode = (node, orgchart, timeout = 700) => {
        const wrapperChart = orgchart.closest("div");
        if (wrapperChart && wrapperChart.offsetWidth && node) {
            setTimeout(() => {
                let lastTf = window.getComputedStyle(orgchart).transform;
                let containerPartWidth = orgchart.offsetWidth/2 - 100;
                let containerPartHeight = orgchart.offsetHeight/2 - 220;

                let newX = 0, newY = 0;
                if (lastTf === 'none') {
                    orgchart.style.transform = 'matrix(1, 0, 0, 1, 0, 0)';
                    newX = containerPartWidth - node.offsetLeft;
                    newY = containerPartHeight - node.offsetTop;
                    if (!lastTf.includes('3d')) {
                        orgchart.style.transform = 'matrix(1, 0, 0, 1, ' + newX + ', ' + newY + ')';
                    } else {
                        orgchart.style.transform = 'matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, ' + newX + ', ' + newY + ', 0, 1)';
                    }
                } else {
                    let matrix = lastTf.split(',');
                    newX = containerPartWidth - node.offsetLeft;
                    newY = containerPartHeight - node.offsetTop;
                    if (!lastTf.includes('3d')) {
                        matrix[4] = newX * matrix[3];
                        matrix[5] = (newY * matrix[3])  + ')';
                    } else {
                        matrix[12] = newX * matrix[3];
                        matrix[13] = (newY * matrix[3]);
                    }
                    orgchart.style.transform = matrix.join(',');
                }
            }, 700);
        }
    };

    render() {
        const  {classes, data, showAvatar, colorOrg} = this.props;

        let levelColor = 5 - parseInt(data.level || 0);
        if(levelColor < 1) levelColor = 1;

        const bgColor = colors?.[`${colorOrg}${levelColor}`];
        const color = data.level > 2 ? dark : white;

        const headerTitle = (
            <>
                <span className={classes.styleOverFlowText}>
                    {data?.OrgChartName || ""}
                </span>
                &nbsp;
                {(Number(data?.Quantity) || Number(data?.Quantity) === 0) &&
                    <span style={{whiteSpace:'nowrap'}}>
                        {` (${Number(data?.Quantity)})`}
                    </span>
                }
            </>
        );

        const lengthUser = data.OrgCharts?.length > 6 ? 7 : data.OrgCharts.length;
        const widthUser = lengthUser > 5 ? `calc(((100% - 20px) / ${lengthUser}))` : 40;

        let user = Config.getUser({EmployeeID: data?.OrgCharts?.[0].RepresentEmployeeID});

        return (
            <>
                <div>
                    <div className={classes.wrapperItem + (data?.countChildren > 0 ? " " + classes.cursorPointer : "")}
                         style={{minHeight: 40 + (showAvatar ? 72 : 0)}}
                         onClick={this.showPopup}
                    >
                        {/*Title*/}
                        <div style={{
                            backgroundColor:bgColor,
                            padding: !showAvatar ? `${spacing(2.5)}px ${spacing(4)}px ${spacing(5)}px` : `${spacing(2.5)}px ${spacing(4)}px`,
                            minHeight: 40,
                            borderRadius: `4px 4px ${!showAvatar ? '4px 4px' : '0 0'}`
                        }}>
                            <Tooltip title={headerTitle} style={{width:'100%'}} direction={"up"}>
                                <Typography className={classes.styleOrgChartName} type={'h3'} style={{color: color}}>
                                    {headerTitle}
                               </Typography>
                            </Tooltip>
                        </div>
                        {/*Content*/}
                        <div className={classes.footerItem} style={{
                            padding: showAvatar ? '8px 16px 24px' : 0,
                        }}>
                            {showAvatar && data?.OrgCharts?.length > 1 && //TH không có RepresentEmployeeID thì hiện danh sách avatar
                                <div style={{
                                    margin: 'auto',
                                    width: lengthUser > 4 ? '100%' : lengthUser * 55,
                                    justifyContent: 'space-around',
                                    display:'flex'
                                }}>
                                    {[...new Array(lengthUser).keys()].map(key => {
                                        const style = {width: widthUser, overflow: ' inherit', zIndex: key};
                                        user = Config.getUser({EmployeeID: data.OrgCharts?.[key].RepresentEmployeeID});
                                        if (key > 6) return null;
                                        if (key === 6) return (
                                            <div className={"display_row align-start valign-middle"}
                                                 style={style}
                                                 key={`OrgCharts-${key}`}
                                            >
                                                <Typography type={'h3'}
                                                            className={classes.showMoreUser}
                                                            style={{zIndex: 6}}
                                                >
                                                    {data.OrgCharts.length > 101 ? '99+' : `+${parseInt(data.OrgCharts.length || 0) - 6}`}
                                                </Typography>
                                            </div>
                                        );

                                        return (
                                            <div className={"display_row align-start valign-middle"}
                                                 style={style}
                                                 key={`OrgCharts-${key}`}
                                            >
                                                <Avatar
                                                    clearAble={false}
                                                    width={40}
                                                    height={40}
                                                    readOnly
                                                    data={{
                                                        [Config.lang("Nhan_vien")]: `${user?.EmployeeID || ""} - ${user?.EmployeeName || ""}`,
                                                        [Config.lang("Phong_ban")]: user?.DepartmentName ?? "",
                                                        [Config.lang("DHR_Chuc_vu")]: user?.DutyName ?? "",
                                                        [Config.lang("DHR_Ngay_vao_lam")]: Config.convertDate(user?.DateJoined, '', 'L') || "",
                                                    }}
                                                    hoverAble={true}
                                                    style={{backgroundColor: white}}
                                                    src={user?.UserPictureURL}
                                                />
                                            </div>
                                        );
                                    })}
                                </div>
                            }
                            {showAvatar && data?.OrgCharts?.length === 1 && data?.OrgCharts?.[0].RepresentEmployeeID && //TH có RepresentEmployeeID thì avatar + thông tin User
                                <div className={"display_row"} style={{width:'100%', justifyContent: 'flex-start'}}>
                                    <Avatar
                                        clearAble={false}
                                        width={40}
                                        height={40}
                                        readOnly
                                        data={{
                                            [Config.lang("Nhan_vien")]:`${user?.EmployeeID || ""} - ${user?.EmployeeName || ""}`,
                                            [Config.lang("Phong_ban")]:user?.DepartmentName ?? "",
                                            [Config.lang("DHR_Chuc_vu")]:user?.DutyName ?? "",
                                            [Config.lang("DHR_Ngay_vao_lam")]:Config.convertDate(user?.DateJoined,'','L') || "",
                                        }}
                                        hoverAble={true}
                                        src={user?.UserPictureURL}
                                    />
                                    <div style={{marginLeft: 8, maxWidth: 'calc(100% - 40px)'}}>
                                        <Typography type={'h4'} className={"overflow-one-line"}>
                                            {user.EmployeeName || ""}
                                        </Typography>
                                        <Typography type={'p2'}
                                                    style={{color: dark6}}
                                                    className={"overflow-one-line"}>
                                            {user.DutyName || ""}
                                        </Typography>
                                    </div>
                                </div>
                            }
                            {data?.countChildren > 0 &&
                                <Typography type="h6"
                                            onClick={this.toggleShowItem}
                                            className={`display_col align-center valign-middle ${classes.nodeNumber}`}
                                            style={{backgroundColor:bgColor, color: color}}
                                >
                                        {parseInt(data?.countChildren || 0) > 99 ? '99+' : data?.countChildren}
                                </Typography>
                            }
                        </div>
                    </div>
                </div>
                <i className="edge verticalEdge topEdge fa" />
                <i className="edge horizontalEdge rightEdge fa" />
                <i className="edge horizontalEdge leftEdge fa" />
                <i className="edge verticalEdge bottomEdge fa" />
            </>
        );
    }

}

export default withStyles(styles, {withTheme: true})(ItemOrgChart);
