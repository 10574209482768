import React, { Component } from "react";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";

import Config from "../../../../config";
import * as mainActions from "../../../../redux/main/main_actions";

import _ from "lodash";
import moment from "moment";
import { browserHistory } from "react-router";
import { useTheme } from "diginet-core-ui/theme";
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Badge,
    ButtonIcon,
    Popover,
    PopoverBody,
    TabContainer,
    TabItem,
    TabHeader,
    CircularProgress,
    Icon,
    Row,
    Col,
    Typography,
    Button,
    Image,
} from "diginet-core-ui/components";
const { spacing } = useTheme();

class W09F7000 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpenReminder: false,
            loadingReminderMaster: false,
            loadingReminderDetails: {},
            tabValue: 0,
            expanded: {},
        };
        this.scrollTypeReminder = 0;
        this.paramsLoadReminder = {
            Master: {
                limit: 20,
                skip: 0,
            },
            Details: {
                limit: 10,
                skip: 0,
                AlertBaseID: "",
            },
        };
    }

    getWidthText = (text = "", fontSize = 0) => {
        this.element = document.createElement("canvas");
        this.context = this.element.getContext("2d");
        this.context.font = `${fontSize}px  Nunito sans-serif`;
        return this.context.measureText(text).width;
    };

    handleScroll = (e, type) => {
        const { dataListReminderMaster, dataListReminderDetails } = this.props;
        const target = e.target;
        const typeLoadReminder = ["Master", "Details"];
        const getTypeReminder = typeLoadReminder[type];
        const getDataListReminder = !!type ? dataListReminderDetails : dataListReminderMaster;
        if (_.eq(_.ceil(target.scrollHeight - target.scrollTop), target.clientHeight)) {
            if (!_.gte(_.size(getDataListReminder.rows), getDataListReminder.total)) {
                this.paramsLoadReminder[getTypeReminder].skip += this.paramsLoadReminder[getTypeReminder].limit;
                this.loadListReminder(type);
            }
        }
    };

    loadListReminder = (type = 0) => {
        const { tabValue, expanded } = this.state;
        const tabValueString = tabValue === 0 ? "" : tabValue === 1 ? "today" : "delays";
        const { Details } = this.paramsLoadReminder;
        const typeLoadReminder = ["Master", "Details"];
        const getAlertBaseIDCurrent = _.get(Details, "AlertBaseID", "");
        const getTypeReminder = typeLoadReminder[type];
        const keyLoading = `loadingReminder${getTypeReminder}`;
        const apiActions = `getListReminder${getTypeReminder}`;
        this.setState({ [keyLoading]: !!type ? { [getAlertBaseIDCurrent]: true } : true });
        this.props.mainActions[apiActions](tabValueString, this.paramsLoadReminder[getTypeReminder], err => {
            if (!!type) this.setState({ expanded: { ...expanded, [getAlertBaseIDCurrent]: true } });
            setTimeout(() => {
                this.setState({ [keyLoading]: !!type ? { [getAlertBaseIDCurrent]: false } : false });
            }, 250);
            if (err) {
                Config.popup.show("ERROR", err);
                return false;
            }
        });
    };

    onClickIgnoreReminder = dataParams => {
        this.props.mainActions.ignoreReminder(dataParams, err => {
            if (err) {
                Config.popup.show("ERROR", err);
                return false;
            }
            Config.notify.show("success", Config.lang("Bo_qua_thong_bao_thanh_cong"), 3000);
            this.props.mainActions.setReminderDetailsEmpty();
            this.loadListReminder(1);
        });
    };

    onClickVoucherAlert = data => {
        const { toggleMenuESS } = this.props;
        toggleMenuESS && toggleMenuESS(Number(data.Type ?? 0), false);
        browserHistory.push({
            pathname: Config.getRootPath() + _.get(data, "FormID", ""),
            state: { voucher_id: _.get(data, "KeyID", "") },
        });
    };

    onChangeTab = (_, tabValue) => {
        this.paramsLoadReminder = {
            Master: {
                limit: 20,
                skip: 0,
            },
            Details: {
                limit: 10,
                skip: 0,
                AlertBaseID: "",
            },
        };
        this.setState({ tabValue, expanded: {} }, () => {
            this.props.mainActions.setReminderDetailsEmpty();
            this.props.mainActions.setReminderMasterEmpty();
            this.loadListReminder();
        });
    };

    onClickExpand = (AlertBaseID = "", total) => {
        if(!Number(total)) return;
        const { expanded } = this.state;
        const { Details } = this.paramsLoadReminder;
        if (Details.AlertBaseID !== AlertBaseID) {
            this.paramsLoadReminder.Details = {
                skip: 0,
                limit: 10,
                AlertBaseID,
            };
            this.setState({ expanded: { ..._.mapValues(expanded, () => false), [AlertBaseID]: true } }, () => {
                setTimeout(() => {
                    this.props.mainActions.setReminderDetailsEmpty();
                    this.loadListReminder(1);
                }, 80);
            });
        } else {
            this.setState({ expanded: { ...expanded, [AlertBaseID]: !expanded[AlertBaseID] } });
        }
    };

    render() {
        const { tabValue, loadingReminderMaster, loadingReminderDetails, expanded } = this.state;
        const { dataListReminderMaster, dataListReminderDetails } = this.props;
        return (
            <Popover
                width={600}
                direction="bottom"
                anchor={
                    <ButtonIcon
                        className={"mgr2x"}
                        viewType={"text"}
                        onClick={() => {
                            const { Master } = this.paramsLoadReminder;
                            if (dataListReminderMaster.total > 0) {
                                Master.skip = 0;
                                Master.limit = 20;
                                this.loadListReminder();
                            }
                        }}
                    >
                        <Badge
                            color="danger"
                            contentDirection="right"
                            iconProps={{
                                color: "primary",
                            }}
                            name="AssignmentTurnedIn"
                        />
                    </ButtonIcon>
                }
            >
                <PopoverBody>
                    <Row>
                        <Col xs={12} className={"display_row align-center"}>
                            <Icon name={"AssignmentFilled"} className={"mgr2x"} color={"dark"} width={32} height={32} />
                            <Typography type={"t3"} color={"dark"}>
                                {Config.lang("Nhac_nho1")}
                            </Typography>
                        </Col>
                        <Col xs={12}>
                            <TabContainer tabIndex={tabValue}>
                                <TabHeader>
                                    <TabItem
                                        disabled={loadingReminderMaster}
                                        index={0}
                                        label={Config.lang("Tat_ca")}
                                        onClick={this.onChangeTab}
                                    />
                                    <TabItem
                                        disabled={loadingReminderMaster}
                                        index={1}
                                        label={Config.lang("Hom_nay")}
                                        onClick={this.onChangeTab}
                                    />
                                    <TabItem
                                        disabled={loadingReminderMaster}
                                        index={2}
                                        label={Config.lang("Tre_han")}
                                        onClick={this.onChangeTab}
                                    />
                                </TabHeader>
                            </TabContainer>
                        </Col>
                    </Row>
                    <div
                        onScroll={e => {
                            if (!_.includes(expanded, true)) {
                                this.handleScroll(e, 0);
                            }
                        }}
                        style={{ maxHeight: 395, overflowY: "scroll" }}
                    >
                        {!loadingReminderMaster &&
                            dataListReminderMaster?.rows?.map((item, i) => {
                                const getAlertBaseID = _.get(item, "AlertBaseID", "");
                                const loadingListReminderDetails = _.get(loadingReminderDetails, getAlertBaseID, false);
                                return (
                                    <Accordion
                                        disabled={loadingListReminderDetails}
                                        key={i}
                                        boxShadow={false}
                                        expand={_.get(expanded, `${getAlertBaseID}`, false)}
                                        onClick={() => this.onClickExpand(getAlertBaseID, item?.totalDetail)}
                                    >
                                        <AccordionSummary
                                            expandIconAt={"end"}
                                            collapseIcon={"ArrowDown"}
                                            expandIcon={Number(item?.totalDetail) ? "ArrowLeft" : null}
                                        >
                                            <div className={"display_row full_w"}>
                                                <Image
                                                    width={spacing(7)}
                                                    height={spacing(7)}
                                                    style={{ marginTop: spacing(-1) }}
                                                    className={"mgr2x fit-content"}
                                                    src={require("../../../../assets/images/general/logodhr.svg")}
                                                />
                                                <Typography className={"mgr1x"} type={"h3"} color={"primary"}>
                                                    {item?.AlertBaseName}
                                                </Typography>
                                                <Typography
                                                    type={"h3"}
                                                    color={"primary"}
                                                    className={"fit-content pdl2x"}
                                                >
                                                    ({item?.totalDetail ?? 0})
                                                </Typography>
                                            </div>
                                        </AccordionSummary>
                                        <AccordionDetails className={"pd0 valign-middle"} style={{ minHeight: 80 }}>
                                            <div
                                                onScroll={e => this.handleScroll(e, 1)}
                                                style={{
                                                    maxHeight: 270,
                                                    overflowY: "scroll",
                                                    overflowX: "hidden",
                                                }}
                                            >
                                                <div className={"display_col full_w"}>
                                                    {dataListReminderDetails?.rows?.map((itemAlert, iAlert) => {
                                                        const isOutOfDate = moment().isAfter(itemAlert?.validDate);
                                                        return (
                                                            <Button
                                                                key={iAlert}
                                                                style={{ width: "100%", textTransform: "inherit" }}
                                                                className={"pd2x"}
                                                                color={"info"}
                                                                labelProps={{ style: { width: "100%" } }}
                                                                onClick={() => this.onClickVoucherAlert(itemAlert)}
                                                            >
                                                                <div
                                                                    className={
                                                                        "display_row align-center align-between full_w"
                                                                    }
                                                                >
                                                                    <div>
                                                                        <div className={"display_row pdb2x"}>
                                                                            <Icon
                                                                                name={"ReviewOutline"}
                                                                                color={"dark"}
                                                                                width={spacing(5)}
                                                                                height={spacing(5)}
                                                                                className={"mgr1x"}
                                                                            />
                                                                            <Typography type={"h4"} color={"dark"}>
                                                                                {itemAlert?.VoucherDes}:
                                                                            </Typography>
                                                                        </div>
                                                                        <Typography>{itemAlert?.VoucherNo}</Typography>
                                                                        <div className={"display_row"}>
                                                                            <Typography
                                                                                color={isOutOfDate ? "danger" : "rest"}
                                                                            >
                                                                                {Config.convertDate(
                                                                                    itemAlert?.ValidDate,
                                                                                    "",
                                                                                    "DD/MM/YYYY HH:mm"
                                                                                )}
                                                                            </Typography>
                                                                            {isOutOfDate && (
                                                                                <>
                                                                                    <Typography
                                                                                        className={"mgr2x"}
                                                                                        type={"h2"}
                                                                                    >
                                                                                        .
                                                                                    </Typography>
                                                                                    <Typography>
                                                                                        {Config.lang("Qua_han")}
                                                                                    </Typography>
                                                                                </>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                    <Button
                                                                        viewType={"link"}
                                                                        label={Config.lang("Bo_qua")}
                                                                        style={{ zIndex: 1 }}
                                                                        color={"dark6"}
                                                                        onClick={() =>
                                                                            this.onClickIgnoreReminder(itemAlert)
                                                                        }
                                                                    />
                                                                </div>
                                                            </Button>
                                                        );
                                                    })}
                                                </div>
                                                <LoadingContent
                                                    loading={loadingListReminderDetails}
                                                    data={dataListReminderDetails?.rows}
                                                />
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                );
                            })}
                        <LoadingContent loading={loadingReminderMaster} data={dataListReminderMaster?.rows} />
                    </div>
                </PopoverBody>
            </Popover>
        );
    }

    componentDidMount() {
        this.loadListReminder();
    }
}

const LoadingContent = props => {
    const { loading, data } = props;
    return (
        <>
            {loading && (
                <div style={{ zIndex: 99 }} className={"full_w display_row valign-middle mg4x"}>
                    <CircularProgress color="primary" size="xs" />
                </div>
            )}
            {!loading && !data?.length && (
                <Typography className={"full_w mg4x"} center>
                    {Config.lang("Khong_co_thong_bao_nhac_nho_nao")}
                </Typography>
            )}
        </>
    );
};

export default compose(
    connect(
        state => ({
            dataListReminderMaster: state.main.dataListReminderMaster,
            dataListReminderDetails: state.main.dataListReminderDetails,
        }),
        dispatch => ({
            mainActions: bindActionCreators(mainActions, dispatch),
        })
    )
)(W09F7000);
