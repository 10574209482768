
import React                         from "react";
import {withStyles}                  from "@material-ui/core";
import OrganizationChart             from "../../../libs/org-chart/orgchart-webcomponents";
import Config                        from "../../../../config";
import {bindActionCreators, compose} from "redux";
import {connect}                     from "react-redux";
import * as W09F4000Actions          from "../../../../redux/W0X/W09F4000/W09F4000_actions";
import {LoadPanel}                   from "devextreme-react";
import PropTypes                     from "prop-types";
import _                             from "lodash";
const styles = {
    wrapperChart: {
        overflow: "auto",
        minHeight: 'calc(100vh - 135px)',
        "& .orgchart": {
            backgroundImage: 'none',
            '& ul > li > ul li::before': {
                borderTopColor: '#9597A1'
            },
            '& ul li .oc-node:not(:only-child)::after': {
                backgroundColor: '#9597A1'
            },
            '& ul > li > ul li > .oc-node::before': {
                backgroundColor: '#9597A1'
            },
            '& table':{
                margin:'auto'
            }
        }
    },

};

class OrgChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            iPermission: 0,
            dataOrgChart: [],
            loading: false,
        };
        this.orgChart = null;
        this._dataOrgChart = [];
        this.dataOrgChart = [];
        this.depthOrgChart = props.depth || props.depth === 0 ? props.depth : 3 ;

    }

    loadDataFormOrgChart = () => {
        const { OrgChartID, valueExpr, FormID, DutyID, tabID } = this.props;
        const params = {
            FormID: FormID,
            Language: Config.language || 84,
            SearchValue: tabID === 0 ? OrgChartID ? OrgChartID : "" : DutyID || "",
        };
        this.setState({loading: true});
        this.props.w09f4000Actions.getDataForm(params, async (error,data) => {
            if (error) {
                let message = error.message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
            if(data) {
                const dataGroup = _.uniqBy(data, valueExpr);
                this._dataOrgChart = data; //store data chart original
                this.dataOrgChart = dataGroup;
                await this._createOrgChart(dataGroup, this.depthOrgChart);
            }
        });
    };

    recursionDataOrgChart = async (parentItem, data, depth, search, parentExpanded = false) => {
        const {dataForm} = this.state;
        const {valueExpr, parentIDExpr, search: _search} = this.props;
        const isSearch = !_.isEmpty(search) || !_.isEmpty(_search);
        let _data = data ? data : dataForm;
        let items = [];
        if (!parentItem) {
            items = _data.filter(d => d[parentIDExpr] === d[valueExpr]);
        } else {
            items = _data.filter(d => d[parentIDExpr] === parentItem[valueExpr]);

        }
        let _expanded = false;
        let _items = [];
        for(let item of items) {
            if (!parentItem) delete item[parentIDExpr];
            if (isSearch) {
                item.level = parentItem ? parentItem.level + 1 : 0;
                let check = false;
                item.expanded = false;
                if (search.includes(item[valueExpr])) {
                    check = true;
                    _expanded = true;
                    item.expanded = true;
                }
                const {children, expanded} = await this.recursionDataOrgChart(item, _data, depth, search, check);
                if (expanded) {
                    _expanded     = true;
                    item.expanded = true;
                    if (children && children.length > 0) {
                        item.children      = children;
                        item.countChildren = children.length;
                    }
                }
                if (check || expanded) _items.push(item);
            } else {
                item.level = parentItem ? parentItem.level + 1 : 0;
                const {children} = await this.recursionDataOrgChart(item, _data, depth, search);
                item.expanded = item.level < depth - 1;
                if (children && children.length > 0) {
                    item.children = children;
                    item.countChildren = children.length;
                }
            }
        }
        return !parentItem ? items : {children: isSearch ? _items : items, expanded: _expanded};
    };

    _parseSearchParams = (search) => {
        search = search || this.props.search || null;
        const {valueExpr, childrenExpr} = this.props;
        let _search = [];
        if (!_.isEmpty(search)) {
            const data = this._dataOrgChart; //get data chart original
            for (let key of Object.keys(search)) {
                if (key === valueExpr) {
                    if (!Array.isArray(search[key])) {
                        _search.push(search[key]);
                    } else {
                        _search = _search.concat(search[key]);
                    }
                } else {
                    let _data = [];
                    if (childrenExpr) {
                        for (let d of data) {
                            _data = _data.concat(d[childrenExpr]);
                        }
                    } else {
                        _data = data;
                    }
                    const listItems = _data.filter(d => {
                        return Array.isArray(search[key]) ? search[key].includes(d[key]) : search[key] === d[key];
                    }).map(d => d[valueExpr]);
                    _search = _search.concat(listItems);
                }
            }
        }
        return [...new Set(_search)];
    };

    _createOrgChart = async (dataSource, depth = 999) => {
        this.setState({loading: true}, async () => {
            let _data = JSON.parse(JSON.stringify(dataSource));
            const search = this._parseSearchParams();
            _data = await this.recursionDataOrgChart(null, _data, depth, search);
            const {id, valueExpr, displayExpr, orgChartProps, renderItem} = this.props;
            if (this.orgChart && _data.length > 0) {
                const obj = {
                    data:        _data[0],
                    nodeId:      valueExpr,
                    nodeContent: displayExpr,
                    // zoom:        true,
                    pan: true,
                    depth:       depth ? depth : 999,
                    nodeTitle:   displayExpr,
                    chartContainer: "#" + id,
                    ...orgChartProps
                };
                if (renderItem) {
                    obj.createNode = (node, data) => {
                        renderItem(node, data, orgchart);
                        // setTimeout(() => {
                            // const parent = node.closest("table");
                            // let line = parent.querySelectorAll("tr.lines");
                            // line = line && line.length > 0 ? line[0] : null;
                            // if (line) {
                            //     const td = line.querySelector("td");
                            //     const spanNum = document.createElement("div");
                            //     spanNum.classList.add("spanNum");
                            //     spanNum.innerHTML = '<span>'+data?.countChildren || 0 +'</span>';
                            //     td.appendChild(spanNum);
                            // }
                        // }, 500);
                    };
                }
                let orgchart = new OrganizationChart(obj);
                this.orgChart.innerHTML = '';
                this.orgChart.appendChild(orgchart);
                orgchart.addEventListener("wheel", (e) => this.onWheeling(e, orgchart));
                this.setState({loading: false});
            } else {
                this.orgChart.innerHTML = '';
                this.setState({loading: false});
            }
        });
    };

    setChartScale = (chart, newScale) => {
        let lastTf = window.getComputedStyle(chart).transform;
        if (lastTf === 'none') {
            chart.style.transform = 'scale(' + newScale + ',' + newScale + ')';
            chart.dataset.scale = newScale;
        } else {
            let matrix = lastTf.split(',');
            if (!lastTf.includes('3d')) {
                if ((newScale === 0.8 && matrix[3] >= 0.6) || (newScale === 1.2 && matrix[3] <= 2)) {
                    chart.dataset.scale = matrix[3].trim();
                    matrix[0] = 'matrix(' + newScale;
                    matrix[3] = newScale;
                    chart.style.transform = lastTf + ' scale(' + newScale + ',' + newScale + ')';
                }
            } else {
                chart.dataset.scale = matrix[3].trim();
                chart.style.transform = lastTf + ' scale3d(' + newScale + ',' + newScale + ', 1)';
            }
        }

    }

    onWheeling = (event, orgchart) => {
        event.preventDefault();

        let newScale = event.deltaY > 0 ? 0.8 : 1.2;

        this.setChartScale(orgchart, newScale);
    };

    refreshChart = (depth = 999) => {
        this.setState({loading: true}, () => {
            setTimeout(() => {
                this.depthOrgChart = depth;
                this._createOrgChart(this.dataOrgChart, depth);
            }, 100);
        });
    };

    componentDidMount = async () => {
        const {dataSource} = this.props;
        if (!dataSource) {
            this.loadDataFormOrgChart();
        } else {
            await this._createOrgChart(dataSource, this.depthOrgChart);
        }

        // libs.mouse.dragToScroll(this.orgChart);
    };

    render() {
            const {classes, id} = this.props;
            const {loading} = this.state;
            return (
                <div>
                    <LoadPanel
                        position={{ my: 'center' }}
                        visible={!!loading}
                        showIndicator={true}
                        shading={true}
                        showPane={true}
                    />
                    <div ref={ref => this.orgChart = ref}
                         id={id}
                         className={"custom-orgchart " + classes.wrapperChart}/>
                </div>
            );
    }

}

OrgChart.propTypes = {
    valueExpr: PropTypes.string,
    displayExpr: PropTypes.string,
    orgChartProps: PropTypes.object,
    dataSource: PropTypes.any,
    id: PropTypes.string.isRequired,
    childrenExpr: PropTypes.string,

    renderItem: PropTypes.func,
};

export default compose(connect(null,
    (dispatch) => ({
        w09f4000Actions: bindActionCreators(W09F4000Actions, dispatch),
    }), null, { forwardRef: true }), withStyles(styles))(OrgChart);
